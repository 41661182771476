import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Col, DatePicker, Form, Input, message, Modal, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/sr_RS';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import 'moment/locale/sr';
import React, { useEffect, useState } from 'react';
import settings from '../../../settings';
import api from '../../../utils/api';

const AddSawlistModal = ({ children, onOk, ...rest }) => {
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const [suppliers, setSuppliers] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (visible) {
      api
        .get(`suppliers`, { params: { group: true, active: true } })
        .then((response) => {
          setSuppliers(response.data.suppliers);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [visible]);

  const onFinish = (values) => {
    let key = 'sawlistCreation';
    message.loading({ content: 'Dodavanje liste prerade...', key });
    setLoading(true);
    api
      .post(`sawlist/add`, {
        date: values.date.toISOString(),
        supplier: values.supplierid,
      })
      .then((response) => {
        message.success({
          content: `Lista prerade dobavljača ${response.data.result.supplier.name} uspješno dodana!`,
          key,
          duration: 5,
        });
        setVisible(false);
        form.resetFields();
        onOk();
        setLoading(false);
      })
      .catch((error) => {
        message.error({ content: 'Greška pri dodavanju liste prerade.', key, duration: 5 });
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Modal
        confirmLoading={loading}
        visible={visible}
        title='Nova lista prerade'
        okText='Dodaj listu prerade'
        cancelText='Otkaži'
        onCancel={() => setVisible(false)}
        onOk={() => {
          form.submit();
        }}>
        <Form layout='vertical' size='middle' requiredMark={false} onFinish={onFinish} form={form}>
          <Form.Item name='supplierid' noStyle>
            <Input type='hidden' />
          </Form.Item>
          <Form.Item
            name='supplier'
            label='Ime dobavljača'
            rules={[
              {
                required: true,
                message: 'Odaberite dobavljača!',
              },
            ]}>
            <AutoComplete
              notFoundContent='Ne postoji traženi dobavljač.'
              options={
                suppliers &&
                Object.keys(suppliers).map((letter) => {
                  return {
                    label: letter,
                    options: suppliers[letter].map((supplier) => {
                      return {
                        key: supplier.id,
                        label: (
                          <Row>
                            <Col flex='auto'>
                              <Text strong>{supplier.name}</Text>
                            </Col>
                            <Col flex='20%'>
                              <Text>{supplier.tax ? 'Pravno lice' : 'Fizičko lice'}</Text>
                            </Col>
                          </Row>
                        ),
                        value: supplier.name,
                      };
                    }),
                  };
                })
              }
              onChange={(_, option) =>
                form.setFields([{ name: 'supplierid', value: option && option.key }])
              }
              allowClear
              filterOption={(inputValue, option) =>
                option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }>
              <Input prefix={<SearchOutlined />} placeholder='Pretražite dobavljače' />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            name='date'
            label='Datum'
            initialValue={moment(new Date(), settings.date)}
            rules={[
              {
                required: true,
                message: 'Odaberite datum!',
              },
            ]}>
            <DatePicker style={{ width: '100%' }} locale={locale} format={settings.date} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddSawlistModal;
