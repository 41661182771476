import React, { useCallback, useEffect, useState } from 'react';
import {  Col, Descriptions, Pagination, Row, Space, Spin, Table } from 'antd';
import RouteLayout from '../../components/RouteLayout';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory } from 'react-router-dom';
import settings from '../../settings';
import { useQuery } from '../../utils/useQuery';
import moment from 'moment';

const ElementsList = () => {
  const navhistory = useHistory();
  const params = useQuery();
  const [elements, setElements] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(true);
  const elementType = params.get('vrsta');
  const elementClass = params.get('klasa');
  const fromDate = params.get('od') && moment(params.get('od'), settings.date);
  const toDate = params.get('do') && moment(params.get('do'), settings.date);

  const [query, setQuery] = useState({
    page: 0,
    perPage: 50,
    type: elementType,
    class: elementClass,
    start: fromDate && fromDate.toISOString(),
    end: toDate && toDate.toISOString(),
  });

  const getElements = useCallback(() => {
    setLoading(true);
    api
      .get(`elements/list`, { params: query })
      .then((response) => {
        setElements(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [query]);

  useEffect(() => getElements(), [getElements]);

  const columns = [
    {
      title: 'Vrijeme prerade',
      dataIndex: 'processedAt',
      key: 'processedAt',
      render: (value) => moment(value).format(settings.fullDate),
    },
    {
      title: 'Vrsta',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Klasa',
      dataIndex: 'class',
      key: 'class',
    },
    {
      title: 'Debljina (mm)',
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: 'Širina (mm)',
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: 'Dužina (mm)',
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: 'Količina',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: `Zapremina (${settings.volumeUnit})`,
      dataIndex: 'volume',
      key: 'volume',
    },
  ];

  return (
    <RouteLayout
      title={
        <Text>
          Elementi{' '}
          {elements.count ? (
            <Text type='secondary' style={{ fontSize: '15px' }}>
              ({elements.count} stavki ukupno)
            </Text>
          ) : null}
        </Text>
      }
      showBG={false}
      descriptions={
        !loading &&
        elementType && (
          <Descriptions size='middle' column={6}>
            <Descriptions.Item label={<Text strong>Vrsta</Text>}>
              {elementType.charAt(0).toUpperCase() + elementType.slice(1)}
            </Descriptions.Item>
            {elementClass && (
              <Descriptions.Item label={<Text strong>Klasa</Text>}>
                {elementClass.toUpperCase().replace('-', '/')}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={<Text strong>Ukupno komada</Text>}>
              {elements.total}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Ukupna zapremina</Text>}>
              {`${elements.volume} ${settings.volumeUnit}`}
            </Descriptions.Item>
            {fromDate && (
              <Descriptions.Item label={<Text strong>Od</Text>}>
                {fromDate.format(settings.date)}
              </Descriptions.Item>
            )}
            {toDate && (
              <Descriptions.Item label={<Text strong>Do</Text>}>
                {toDate.format(settings.date)}
              </Descriptions.Item>
            )}
          </Descriptions>
        )
      }
      routes={[
        {
          path: '/',
          breadcrumbName: 'Foresta',
        },
        {
          path: settings.route.element,
          breadcrumbName: 'Elementi',
        },
        elementType
          ? {
              breadcrumbName: elementType.charAt(0).toUpperCase() + elementType.slice(1),
            }
          : {},
      ]}>
      {!loading ? (
        <Space direction='vertical' size='middle' style={{ width: '100%' }}>
          <Table
            onRow={(row, rowIndex) => {
              return {
                onClick: () => navhistory.push(settings.route.sawlist.replace(':id', row.sawlist)),
              };
            }}
            bordered={false}
            rowClassName='cursorPointer'
            rowKey={(row) => `${row.id}${row.type}${row.class}${row.volume}`}
            dataSource={elements.elements}
            loading={loading}
            pagination={false}
            columns={columns}
            size='small'
          />
          <Row>
            <Col flex='auto' />
            <Col>
              <Pagination
                current={query.page + 1}
                pageSize={query.perPage}
                pageSizeOptions={[50, 100, 200]}
                showSizeChanger={true}
                onChange={(page, pageSize) =>
                  setQuery({ ...query, page: page - 1, perPage: pageSize })
                }
                total={elements.count}
              />
            </Col>
          </Row>
        </Space>
      ) : (
        <Spin className='loadingPage' />
      )}
    </RouteLayout>
  );
};

export default ElementsList;
