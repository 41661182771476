import { useEffect, useRef } from 'react';
import { socket } from '../utils/api';

const useInvoice = (id, onUpdate) => {
  const callback = useRef(onUpdate);

  useEffect(() => {
    socket.on(`invoice-${id}`, (message) => {
      if (message.caller !== socket.id) {
        callback.current(message.data.type, message.name, message.data.plate);
      }
    });
    return () => {
      socket.off(`invoice-${id}`);
    };
  }, [id]);
};

export default useInvoice;
