import { useEffect, useRef } from 'react';
import { socket } from '../utils/api';

const useInvoices = (onUpdate) => {
  const callback = useRef(onUpdate);

  useEffect(() => {
    socket.on(`sawlist`, (message) => {
      if (message.caller !== socket.id) {
        callback.current(message.data.type, message.name, message.data.supplier);
      }
    });
    return () => {
      socket.off(`sawlist`);
    };
  }, []);
};

export default useInvoices;
