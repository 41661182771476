import React from 'react';
import { Layout, PageHeader } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const RouteLayout = ({ routes = [], title, extra = [], descriptions, children, showBG = true, ...rest }) => (
  <div style={{ overflow: 'auto' }}>
    <PageHeader
      ghost={false}
      title={title}
      breadcrumb={{
        routes,
        itemRender: (route) =>
          route.path ? <Link to={route.path}>{route.breadcrumbName}</Link> : route.breadcrumbName,
      }}
      extra={extra}
      {...rest}>
      {descriptions}
    </PageHeader>
    {<Content className={`mainContent ${showBG && ' mainContentBG'}`}>{children}</Content>}
  </div>
);

export default RouteLayout;
