import React, { useEffect, useState } from 'react';
import { Card, Col, Collapse, DatePicker, Empty, Row, Space, Spin, Statistic, Table } from 'antd';
import RouteLayout from '../../components/RouteLayout';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory } from 'react-router-dom';
import settings from '../../settings';
import { useGlobal } from 'reactn';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { useQuery } from '../../utils/useQuery';

const Element = () => {
  const history = useHistory();
  const params = useQuery();
  const [element, setElement] = useState({});
  const [loading, setLoading] = useState(true);
  const [companySettings] = useGlobal('settings');

  const [dates, setDates] = useState({
    start: params.get('od') && moment(params.get('od'), settings.date),
    end: params.get('do') && moment(params.get('do'), settings.date),
  });

  const getElement = (start, end) => {
    setLoading(true);
    api
      .get(`elements/get`, {
        params: {
          start: start && start.toISOString(),
          end: end && end.toISOString(),
        },
      })
      .then((response) => {
        setElement(response.data);
        setLoading(false);
        window.history.replaceState(
          null,
          null,
          `${settings.route.element}${start ? `?od=${start.format(settings.date)}` : ''}${
            end ? `${start ? '&' : '?'}do=${end.format(settings.date)}` : ''
          }`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => getElement(dates.start, dates.end), [dates]);

  const classColumns = [
    {
      title: 'Klasa',
      dataIndex: 'class',
      key: 'class',
      width: '100%',
    },
    {
      title: 'Elemenata',
      dataIndex: 'total',
      key: 'total',
      width: '250px',
    },
    {
      title: 'Zapremina',
      dataIndex: 'volume',
      key: 'volume',
      width: '400px',
      render: (value) => `${value} ${settings.volumeUnit}`,
    },
  ];

  return (
    <RouteLayout
      title={<Text>Elementi</Text>}
      showBG={false}
      extra={
        <DatePicker.RangePicker
          defaultValue={[dates.start, dates.end]}
          format={settings.date}
          onCalendarChange={(value) => {
            if (value) {
              setDates({ start: value[0], end: value[1] });
              getElement(value[0], value[1]);
            } else {
              getElement();
            }
          }}
          disabledDate={(current) =>
            current &&
            (current < moment('2021-01-01', 'YYYY-MM-DD') ||
              current > moment(new Date(), 'YYYY-MM-DD'))
          }
        />
      }
      routes={[
        {
          path: '/',
          breadcrumbName: 'Foresta',
        },
        {
          breadcrumbName: 'Elementi',
        },
      ]}>
      {!loading && element.types ? (
        Object.keys(element.types).length !== 0 ? (
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            <Card size='small' title='Statistika'>
              <Row>
                <Col flex='1 1 60%'>
                  <Space direction='vertical' style={{ width: '100%' }}>
                    <Row gutter={10}>
                      <Col flex='1 1'>
                        <Card size='small'>
                          <Statistic title='Ukupno elemenata' value={element.total} />
                        </Card>
                      </Col>
                      <Col flex='1 1'>
                        <Card size='small'>
                          <Statistic title='Ukupna zapremina' value={`${element.volume} ${settings.volumeUnit}`} />
                        </Card>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col flex='1 1'>
                        <Card size='small'>
                          <Statistic title='Iskorišteno' value={`${element.efficiency} ${settings.volumeUnit}`} />
                        </Card>
                      </Col>
                      <Col flex='1 1'>
                        <Card size='small'>
                          <Statistic title='Otpad' value={`${element.trash} ${settings.volumeUnit}`} />
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Card size='small' style={{ width: '100%' }}>
                        <Statistic
                          title='Iskorištenost'
                          value={`${element.efficiencyPercentage} %`}
                        />
                      </Card>
                    </Row>
                  </Space>
                </Col>
                <Col flex='1 1 auto'>
                  <Col flex='1 1'>
                    <ReactApexChart
                      series={element.pie.series}
                      height='300px'
                      options={{
                        labels: element.pie.labels,
                        plotOptions: {
                          pie: {
                            donut: {
                              labels: {
                                show: true,
                                value: {
                                  formatter: (val) => `${val} ${settings.volumeUnit}`,
                                },
                                total: {
                                  show: true,
                                  label: 'Iskorištenost',
                                  formatter: () => `${element.efficiencyPercentage} %`,
                                },
                              },
                            },
                          },
                        },
                      }}
                      type='donut'
                    />
                  </Col>
                </Col>
              </Row>
            </Card>
            {Object.keys(element.types).map((type, index) =>
              type === companySettings.trashElement ? (
                <Collapse accordion bordered={true} key={index}>
                  <Collapse.Panel
                    showArrow={false}
                    collapsible='disabled'
                    header={
                      <Row>
                        <Col flex='auto'>
                          <Text strong>{type}</Text>
                        </Col>
                        <Col flex='250px'>
                          <Space size='small'>
                            <Text strong>Ukupna zapremina:</Text>
                            <Text>{`${element.types[type]} ${settings.volumeUnit}`}</Text>
                          </Space>
                        </Col>
                        <Col flex='125px'></Col>
                      </Row>
                    }
                    key={index}
                  />
                </Collapse>
              ) : (
                <Collapse accordion bordered={true} key={index}>
                  <Collapse.Panel
                    header={
                      <Row>
                        <Col flex='auto'>
                          <Text strong>{type}</Text>
                        </Col>
                        <Col flex='250px'>
                          <Space size='small'>
                            <Text strong>Ukupno elemenata:</Text>
                            <Text>{element.types[type].total}</Text>
                          </Space>
                        </Col>
                        <Col flex='250px'>
                          <Space size='small'>
                            <Text strong>Ukupna zapremina:</Text>
                            <Text>{`${element.types[type].volume} ${settings.volumeUnit}`}</Text>
                          </Space>
                        </Col>
                        <Col flex='125px'>
                          <Text
                            type='success'
                            className='cursorPointer'
                            onClick={() =>
                              history.push({
                                pathname: settings.route.elementlist,
                                search: `vrsta=${type.toLowerCase()}`,
                              })
                            }>
                            Vidi listu elemenata
                          </Text>
                        </Col>
                      </Row>
                    }
                    key={index}>
                    {element.types[type].classes && (
                      <Table
                        key={index}
                        tableLayout='auto'
                        rowClassName='cursorPointer'
                        rowKey='total'
                        dataSource={Object.keys(element.types[type].classes).map((clas) => {
                          return { ...element.types[type].classes[clas], class: clas };
                        })}
                        onRow={(row, rowIndex) => {
                          return {
                            onClick: () => {
                              history.push({
                                pathname: settings.route.elementlist,
                                search: `vrsta=${type.toLowerCase()}&klasa=${row.class
                                  .toLowerCase()
                                  .replace('/', '-')}${
                                  dates.start ? `&od=${dates.start.format(settings.date)}` : ''
                                }${dates.end ? `&do=${dates.end.format(settings.date)}` : ''}`,
                              });
                            },
                          };
                        }}
                        columns={classColumns}
                        pagination={false}
                        size='small'
                      />
                    )}
                  </Collapse.Panel>
                </Collapse>
              )
            )}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema proizvedenih elemenata.' />
        )
      ) : (
        <Spin className='loadingPage' />
      )}
    </RouteLayout>
  );
};

export default Element;
