import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Collapse, Descriptions, Empty, Row, Space, Spin, Table } from 'antd';
import RouteLayout from '../../components/RouteLayout';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory } from 'react-router-dom';
import settings from '../../settings';
import TrashLogModal from './TrashLogModal';
import InventoryPrint from './inventoryPrint';

const Trash = () => {
  const history = useHistory();
  const [trash, setTrash] = useState({ data: [], total: 0, volume: 0 });
  const [loading, setLoading] = useState(true);

  const getTrash = useCallback(() => {
    setLoading(true);
    api
      .get(`inventory/trash`)
      .then((response) => {
        setTrash({
          data: response.data.trash,
          total: response.data.total,
          volume: response.data.volume,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => getTrash(), [getTrash]);

  const classColumns = [
    {
      title: 'Klasa',
      dataIndex: 'class',
      key: 'class',
      width: '100%',
    },
    {
      title: 'Trupaca',
      dataIndex: 'total',
      key: 'total',
      width: '250px',
    },
    {
      title: 'Zapremina',
      dataIndex: 'volume',
      key: 'volume',
      width: '400px',
      render: (value) => `${value} ${settings.volumeUnit}`,
    },
  ];

  return (
    <RouteLayout
      title={<Text>Vraćeni trupci</Text>}
      descriptions={
        !loading && (
          <Descriptions size='middle' column={4}>
            <Descriptions.Item label={<Text strong>Ukupno trupaca</Text>}>
              {trash.total}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Ukupna zapremina</Text>}>
              {`${trash.volume} ${settings.volumeUnit}`}
            </Descriptions.Item>
          </Descriptions>
        )
      }
      extra={
        <Space direction='horizontal' size='middle' style={{ width: '100%' }}>
          <InventoryPrint type='trash' />
          <TrashLogModal key='trash' onOk={getTrash}>
            <Button type='primary'>Vrati trupac</Button>
          </TrashLogModal>
        </Space>
      }
      showBG={false}
      routes={[
        {
          path: '/',
          breadcrumbName: 'Foresta',
        },
        {
          breadcrumbName: 'Vraćeni trupci',
        },
      ]}>
      {!loading ? (
        Object.keys(trash.data).length !== 0 ? (
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            {Object.keys(trash.data).map((type, index) => (
              <Collapse accordion bordered={true} key={index}>
                <Collapse.Panel
                  header={
                    <Row>
                      <Col flex='auto'>
                        <Text strong>{type}</Text>
                      </Col>
                      <Col flex='250px'>
                        <Space size='small'>
                          <Text strong>Ukupno trupaca:</Text>
                          <Text>{trash.data[type].total}</Text>
                        </Space>
                      </Col>
                      <Col flex='250px'>
                        <Space size='small'>
                          <Text strong>Ukupna zapremina:</Text>
                          <Text>{`${trash.data[type].volume} ${settings.volumeUnit}`}</Text>
                        </Space>
                      </Col>
                      <Col flex='125px'>
                        <Text
                          type='success'
                          className='cursorPointer'
                          onClick={() =>
                            history.push({
                              pathname: settings.route.trashlist,
                              search: `vrsta=${type.toLowerCase()}`,
                            })
                          }>
                          Vidi listu trupaca
                        </Text>
                      </Col>
                    </Row>
                  }
                  key={index}>
                  <Table
                    key={index}
                    tableLayout='auto'
                    rowClassName='cursorPointer'
                    rowKey='total'
                    dataSource={Object.keys(trash.data[type].classes).map((clas) => {
                      return { ...trash.data[type].classes[clas], class: clas };
                    })}
                    onRow={(row, rowIndex) => {
                      return {
                        onClick: () => {
                          history.push({
                            pathname: settings.route.trashlist,
                            search: `vrsta=${type.toLowerCase()}&klasa=${row.class
                              .toLowerCase()
                              .replace('/', '-')}`,
                          });
                        },
                      };
                    }}
                    columns={classColumns}
                    pagination={false}
                    size='small'
                  />
                </Collapse.Panel>
              </Collapse>
            ))}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema vraćenih trupaca.' />
        )
      ) : (
        <Spin className='loadingPage' />
      )}
    </RouteLayout>
  );
};

export default Trash;
