import { Button, Col, Input, Popconfirm, Row, Table } from 'antd';
import React, { useState } from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import Text from 'antd/lib/typography/Text';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const SortableTable = ({ data, setData, name, field, title, columns }) => {
  const [editable, setEditable] = useState(false);

  const setArrayValue = (index, value) => {
    if (value !== '') {
      data[index][field] = value;
      setData(data);
    } else {
      data.splice(index, 1);
      setData(data);
    }
  };

  columns = editable
    ? [
        {
          title: 'Redosljed',
          dataIndex: 'sort',
          align: 'center',
          width: 30,
          className: 'drag-visible',
          render: () => <DragHandle />,
        },
      ]
        .concat(
          columns.map((column) => {
            return {
              ...column,
              render: (value, row, index) =>
                column.editable ? (
                  <Input
                    defaultValue={value}
                    bordered={true}
                    onPressEnter={(e) => setArrayValue(index, e.target.value)}
                    onBlur={(e) => setArrayValue(index, e.target.value)}
                  />
                ) : (
                  value
                ),
            };
          })
        )
        .concat([
          {
            title: 'Akcije',
            dataIndex: 'actions',
            align: 'center',
            width: 30,
            render: (text, row, index) => (
              <Popconfirm
                title={`Da li ste sigurni da želite da obrišete ${name}?`}
                onConfirm={() => {
                  data.splice(index, 1);
                  setData(data);
                }}
                okText='Obriši'
                placement='rightTop'
                okButtonProps={{ danger: true }}
                cancelText='Otkaži'>
                <DeleteOutlined />
              </Popconfirm>
            ),
          },
        ])
    : columns;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(data), oldIndex, newIndex).filter((el) => !!el);
      setData(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      title={() => (
        <Row>
          <Col flex='1 1 auto'>
            <Text strong>{title}</Text>
          </Col>
          <Col>
            <Button
              type={editable ? 'primary' : 'secondary'}
              onClick={() => setEditable(!editable)}
              icon={<EditOutlined />}>
              Izmjena
            </Button>
          </Col>
        </Row>
      )}
      pagination={false}
      dataSource={data}
      columns={columns}
      size='middle'
      rowKey='index'
      footer={
        editable
          ? () => (
              <Button
                type='dashed'
                block
                onClick={() =>
                  setData([...data, { [field]: '', index: data.length, key: data.length }])
                }>
                Dodaj {name}
              </Button>
            )
          : false
      }
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default SortableTable;
