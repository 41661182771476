import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Empty, message, Row, Space, Spin, Table } from 'antd';
import RouteLayout from '../../components/RouteLayout';
import { UserOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory } from 'react-router-dom';
import settings from '../../settings';
import AddSupplierModal from './modals/AddSupplierModal';
import useSuppliers from '../../socketio/useSuppliers';

const Suppliers = () => {
  const history = useHistory();
  const [suppliers, setSuppliers] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(true);

  const getSuppliers = useCallback((loading = true) => {
    setLoading(loading);
    api
      .get(`suppliers`, { params: { group: true } })
      .then((response) => {
        setSuppliers({ data: response.data.suppliers, total: response.data.total });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => getSuppliers(), [getSuppliers]);

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'active',
      key: 'avatar',
      width: 'min-content',
      render: () => <Avatar icon={<UserOutlined />} />,
    },
    {
      title: 'Info',
      dataIndex: 'name',
      key: 'info',
      width: '100%',
      render: (value, row) =>
        row.number ? (
          <Row>
            <Col>
              <Row>
                <Text strong>{value}</Text>
              </Row>
              <Row>
                <Text strong type='success'>
                  {row.number}
                </Text>
              </Row>
            </Col>
          </Row>
        ) : (
          <Text strong>{value}</Text>
        ),
    },
    {
      title: 'tax',
      dataIndex: 'tax',
      width: 'min-content',
      key: 'tax',
      render: (tax) => (
        <Text style={{ whiteSpace: 'nowrap', float: 'right' }} strong>
          {tax ? 'Pravno lice' : 'Fizičko lice'}
        </Text>
      ),
    },
  ];

  const onUpdate = (event, name, extra) => {
    if (event === 'edit') {
      message.info({
        content: `${name} je upravo izmjenio dobavljača ${extra}.`,
        duration: 3,
      });
    } else if (event === 'delete') {
      message.warning({
        content: `${name} je upravo obrisao dobavljača ${extra}!`,
        duration: 3,
      });
    } else if (event === 'add') {
      message.success({
        content: `${name} je upravo dodao dobavljača ${extra}.`,
        duration: 3,
      });
    }
    getSuppliers(false);
  };

  useSuppliers(onUpdate);

  return (
    <RouteLayout
      title={
        <Text>
          Dobavljači{' '}
          {suppliers.total && suppliers.total !== 0 ? (
            <Text type='secondary' style={{ fontSize: '15px' }}>
              ({suppliers.total} dobavljača ukupno)
            </Text>
          ) : null}
        </Text>
      }
      extra={[
        <AddSupplierModal key='1' onOk={getSuppliers}>
          <Button type='primary'>Dodaj novog dobavljača</Button>
        </AddSupplierModal>,
      ]}
      showBG={false}
      routes={[
        {
          path: '/',
          breadcrumbName: 'Foresta',
        },
        {
          breadcrumbName: 'Dobavljači',
        },
      ]}>
      {!loading ? (
        Object.keys(suppliers.data).length !== 0 ? (
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            {Object.keys(suppliers.data).map((date, index) => (
              <React.Fragment key={index}>
                <Card size='small' title={date}>
                  <Table
                    onRow={(row, rowIndex) => {
                      return {
                        onClick: () => history.push(settings.route.supplier.replace(':id', row.id)),
                      };
                    }}
                    bordered={false}
                    rowClassName='cursorPointer'
                    rowKey='id'
                    showHeader={false}
                    dataSource={suppliers.data[date]}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    size='small'
                  />
                </Card>
              </React.Fragment>
            ))}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema dobavljača.' />
        )
      ) : (
        <Spin className='loadingPage' />
      )}
    </RouteLayout>
  );
};

export default Suppliers;
