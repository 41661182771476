import axios from 'axios';
import settings from '../settings';
import { getUser, logout } from './cookies';
import { io } from 'socket.io-client/dist/socket.io';

const api = axios.create({
  baseURL: settings.baseUrl,
  timeout: 50000,
});

export const socket = io(settings.baseUrl, {
  transports: ['websocket', 'polling', 'flashsocket'],
});

api.interceptors.request.use(async (config) => {
  const token = getUser().token;
  if (token) {
    config.headers.Authorization = getUser().token;
    config.headers.socket = await socket && socket.id;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    let status = error.response.data.status;
    if (
      status === 'incorrect_token' ||
      status === 'unauthorized' ||
      status === 'no_token_provided'
    ) {
      logout();
      window.location.reload(false);
    } else {
      throw error;
    }
  }
);

export default api;
