import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Empty,
  message,
  Popconfirm,
  Radio,
  Row,
  Space,
  Spin,
  Statistic,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from 'antd';
import RouteLayout from '../../components/RouteLayout';
import {
  BarChartOutlined,
  LockTwoTone,
  PicCenterOutlined,
  SnippetsOutlined,
  UnlockTwoTone,
} from '@ant-design/icons';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory, useParams } from 'react-router-dom';
import settings from '../../settings';
import locked from '../../img/locked.svg';
import unlocked from '../../img/unlocked.svg';
import { Content } from 'antd/lib/layout/layout';
import EditSupplierModal from './modals/EditSupplierModal';
import roundingMethod from '../../utils/roundingMethod';
import ReactApexChart from 'react-apexcharts';
import rs from 'apexcharts/dist/locales/rs.json';
import { useGlobal } from 'reactn';
import { useQuery } from '../../utils/useQuery';

const Supplier = () => {
  const history = useHistory();
  const { id } = useParams();
  const params = useQuery();
  const [supplier, setSupplier] = useState();
  const [invoices, setInvoices] = useState();
  const [sawlists, setSawlists] = useState();
  const [loading, setLoading] = useState(true);
  const [invoicesLoading, setInvoicesLoading] = useState(true);
  const [sawlistsLoading, setSawlistsLoading] = useState(true);
  const [companySettings] = useGlobal('settings');

  const [searchDate, setDates] = useState({
    start: params.get('od') && moment(params.get('od'), settings.date),
    end: params.get('do') && moment(params.get('do'), settings.date),
  });

  const getSupplier = useCallback(
    (start, end) => {
      setLoading(true);
      api
        .post(
          `supplier/get`,
          {
            id,
          },
          {
            params: {
              start: start && start.toISOString(),
              end: end && end.toISOString(),
            },
          }
        )
        .then((response) => {
          setSupplier(response.data);
          setLoading(false);
          window.history.replaceState(
            null,
            null,
            `${settings.route.supplier.replace(':id', id)}${
              start ? `?od=${start.format(settings.date)}` : ''
            }${end ? `${start ? '&' : '?'}do=${end.format(settings.date)}` : ''}`
          );
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    },
    [id]
  );

  const getInvoices = useCallback(() => {
    setInvoicesLoading(true);
    api
      .post(`supplier/invoices`, { id })
      .then((response) => {
        setInvoices(response.data);
        setInvoicesLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const getSawlists = useCallback(() => {
    setSawlistsLoading(true);
    api
      .post(`supplier/sawlists`, { id })
      .then((response) => {
        setSawlists(response.data);
        setSawlistsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => getSupplier(searchDate.start, searchDate.end), [searchDate, getSupplier]);

  useEffect(() => {
    getInvoices();
    getSawlists();
  }, [getInvoices, getSawlists]);

  const onToggleActive = (active) => {
    let key = 'supplierToggleActive';
    message.loading({
      content: active ? 'Aktiviranje dobavljača...' : 'Deaktiviranje dobavljača',
      key,
    });
    api
      .post(`supplier/edit`, { id, active })
      .then(() => {
        getSupplier();
        message.success({
          content: `Dobavljač uspješno ${active ? 'aktiviran' : 'deaktiviran'}!`,
          key,
          duration: 5,
        });
      })
      .catch(() => {
        message.error({
          content: `Greška pri ${active ? 'aktiviranju' : 'deaktiviranju'} dobavljača.`,
          key,
          duration: 5,
        });
      });
  };

  const onDelete = () => {
    let key = 'supplierDelete';
    message.loading({
      content: 'Brisanje dobavljača...',
      key,
    });
    api
      .post(`supplier/delete`, { id })
      .then(() => {
        history.push(settings.route.suppliers);
        message.success({
          content: `Dobavljač uspješno obrisan!`,
          key,
          duration: 5,
        });
      })
      .catch(() => {
        message.error({
          content: `Greška pri brisanju dobavljača.`,
          key,
          duration: 5,
        });
      });
  };

  const columns = [
    {
      title: 'Tag',
      dataIndex: 'locked',
      key: 'lockedTag',
      width: 'min-content',
      render: (value) => <img src={value ? locked : unlocked} alt='' />,
    },
    {
      title: 'Info',
      dataIndex: 'number',
      key: 'info',
      width: '100%',
      render: (value, row) => (
        <Row>
          <Col>
            <Row>
              <Text strong>{value}</Text>
            </Row>
            <Row>
              <Text strong type='success'>
                {row.composer ? row.composer.name : supplier.name}
              </Text>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: 'date',
      dataIndex: 'date',
      key: 'date',
      width: 'min-content',
      color: '#8C8C8C',
      render: (value) => (
        <Text strong type='secondary' style={{ whiteSpace: 'nowrap', float: 'right' }}>
          {moment(value).format(settings.date)}
        </Text>
      ),
    },
    {
      title: 'locked',
      dataIndex: 'locked',
      width: 'min-content',
      key: 'locked',
      render: (value, invoice) =>
        value ? (
          <Tooltip title={`Zaključano ${moment(invoice.lockedAt).format(settings.fullDate)}`}>
            <Tag icon={<LockTwoTone twoToneColor='#585858' />} color='default'>
              Zaključano
            </Tag>
          </Tooltip>
        ) : (
          <Tag icon={<UnlockTwoTone twoToneColor='#52C41A' />} color='success'>
            Otključano
          </Tag>
        ),
    },
  ];

  const classColumns = [
    {
      title: 'Klasa',
      dataIndex: 'class',
      key: 'class',
      width: '100%',
    },
    {
      title: 'Prosječna dužina',
      dataIndex: 'averageLength',
      key: 'averageLength',
      width: '250px',
      render: (value) => `${value} ${settings.logUnit}`,
    },
    {
      title: 'Prosječan prečnik',
      dataIndex: 'averageRadius',
      key: 'averageRadius',
      width: '250px',
      render: (value) => `${value} ${settings.logUnit}`,
    },
    {
      title: 'Prosječna zapremina',
      dataIndex: 'averageVolume',
      key: 'averageVolume',
      width: '250px',
      render: (value) => `${value} ${settings.volumeUnit}`,
    },
    {
      title: 'Trupaca',
      dataIndex: 'total',
      key: 'total',
      width: '250px',
    },
    {
      title: 'Zapremina',
      dataIndex: 'volume',
      key: 'volume',
      width: '250px',
      render: (value) => `${value} ${settings.volumeUnit}`,
    },
    {
      title: 'Prerađeno',
      dataIndex: 'processed',
      key: 'processed',
      width: '173px',
    },
  ];

  const elementClassColumns = [
    {
      title: 'Klasa',
      dataIndex: 'class',
      key: 'class',
      width: '100%',
    },
    {
      title: 'Elemenata',
      dataIndex: 'total',
      key: 'total',
      width: '250px',
    },
    {
      title: 'Zapremina',
      dataIndex: 'volume',
      key: 'volume',
      width: '275px',
      render: (value) => `${value} ${settings.volumeUnit}`,
    },
  ];

  const [groupBy, setGroupBy] = useState('volume');
  const [elementsGroupBy, setElementsGroupBy] = useState('volume');

  const dates =
    !loading && supplier.history
      ? Object.keys(supplier.history).sort((a, b) => new Date(a) - new Date(b))
      : null;

  const types = dates
    ? dates.reduce((types, date) => {
        return [...new Set(types.concat(Object.keys(supplier.history[date].types)))];
      }, [])
    : [];

  const elementDates =
    !loading && supplier.processingHistory
      ? Object.keys(supplier.processingHistory).sort((a, b) => new Date(a) - new Date(b))
      : null;

  const elementTypes = elementDates
    ? elementDates.reduce((types, date) => {
        return [...new Set(types.concat(Object.keys(supplier.processingHistory[date].types)))];
      }, [])
    : [];

  return !loading ? (
    supplier ? (
      <RouteLayout
        title={
          <Row gutter={20} align='middle'>
            <Col>{supplier.name}</Col>
            <Col>
              {supplier.tax ? (
                <Tag color='#8b160c'>Pravno lice</Tag>
              ) : (
                <Tag color='default'>Fizičko lice</Tag>
              )}
              {supplier.active ? (
                <Tag color='success'>Aktivan</Tag>
              ) : (
                <Tag color='red'>Deaktiviran</Tag>
              )}
            </Col>
          </Row>
        }
        extra={[
          <Space size='small' onClick={(e) => e.stopPropagation()}>
            {invoices && invoices.length === 0 && (
              <div>
                <Popconfirm
                  title={`Da li ste sigurni da obrišete ovog dobavljača?`}
                  onConfirm={() => onDelete()}
                  okText='Obriši'
                  placement='rightTop'
                  okButtonProps={{ danger: true }}
                  cancelText='Otkaži'>
                  <Button>Obriši</Button>
                </Popconfirm>
              </div>
            )}
            <EditSupplierModal supplier={supplier} onOk={getSupplier}>
              <Button type='secondary'>Izmjeni</Button>
            </EditSupplierModal>
            <div>
              <Popconfirm
                title={`Da li ste sigurni da želite da ${
                  supplier.active ? 'deaktivirate' : 'aktivirate'
                } dobavljača?${
                  supplier.active
                    ? ' Nećete više moći dodavati prijemnice i liste prerade za ovog dobavljača.'
                    : ''
                }`}
                onConfirm={() => onToggleActive(!supplier.active)}
                okText={supplier.active ? 'Deaktiviraj' : 'Aktiviraj'}
                placement='rightTop'
                okButtonProps={{ danger: true }}
                cancelText='Otkaži'>
                <Button>{supplier.active ? 'Deaktiviraj' : 'Aktiviraj'}</Button>
              </Popconfirm>
            </div>
          </Space>,
        ]}
        showBG={false}
        descriptions={
          <Descriptions size='middle' column={3}>
            {supplier.phone && (
              <Descriptions.Item label={<Text strong>Telefon</Text>}>
                {supplier.phone}
              </Descriptions.Item>
            )}
            {supplier.email && (
              <Descriptions.Item label={<Text strong>Email</Text>}>
                <Text
                  type='success'
                  className='cursorPointer'
                  onClick={() => window.open(`mailto:${supplier.email}`)}>
                  {supplier.email}
                </Text>
              </Descriptions.Item>
            )}
            {supplier.address && (
              <Descriptions.Item label={<Text strong>Adresa</Text>}>
                {supplier.address}
              </Descriptions.Item>
            )}
          </Descriptions>
        }
        routes={[
          {
            path: '/',
            breadcrumbName: 'Foresta',
          },
          {
            path: settings.route.suppliers,
            breadcrumbName: 'Dobavljači',
          },
          {
            breadcrumbName: 'Dobavljač',
          },
        ]}>
        <Space direction='vertical' style={{ width: '100%' }} size='large'>
          <Content className='mainContentBG' style={{ padding: '20px' }}>
            <Row>
              <Col flex='1 1 auto'>
                <Text strong>Statistika</Text>
              </Col>
              <Col>
                <DatePicker.RangePicker
                  defaultValue={[searchDate.start, searchDate.end]}
                  format={settings.date}
                  onCalendarChange={(value) => {
                    if (value) {
                      setDates({ start: value[0], end: value[1] });
                      getSupplier(value[0], value[1]);
                    } else {
                      getSupplier();
                    }
                  }}
                  disabledDate={(current) =>
                    current &&
                    (current < moment('2021-01-01', 'YYYY-MM-DD') ||
                      current > moment(new Date(), 'YYYY-MM-DD'))
                  }
                />
              </Col>
            </Row>
            <Tabs type='card' defaultActiveKey='1' style={{ marginTop: '10px' }}>
              <Tabs.TabPane
                tab={
                  <span>
                    <BarChartOutlined />
                    Generalno
                  </span>
                }
                key='1'>
                <Space size={16} direction='vertical' style={{ width: '100%' }}>
                  <Row gutter={16}>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic title='Prijemnica' value={supplier.invoicesCount || 0} />
                      </Card>
                    </Col>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Prosječna zapremina prijemnice'
                          value={`${supplier.averageVolume || 0} ${settings.volumeUnit}`}
                        />
                      </Card>
                    </Col>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title={`Prosječna cijena po ${settings.volumeUnit}`}
                          value={`${supplier.averagePricePerVolume || 0} ${settings.currency}`}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Prosječna cijena prijemnice'
                          value={`${supplier.averagePrice || 0} ${settings.currency}`}
                        />
                      </Card>
                    </Col>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Prosječno trupaca po prijemnici'
                          value={supplier.averageTotal || 0}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Space>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <SnippetsOutlined />
                    Trupci
                  </span>
                }
                key='2'>
                <Space size={16} direction='vertical' style={{ width: '100%' }}>
                  <Row gutter={16}>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Prosječna dužina trupca'
                          value={`${(supplier.logs && supplier.logs.averageLength) || 0} ${
                            settings.logUnit
                          }`}
                        />
                      </Card>
                    </Col>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Prosječan prečnik trupca'
                          value={`${(supplier.logs && supplier.logs.averageRadius) || 0} ${
                            settings.logUnit
                          }`}
                        />
                      </Card>
                    </Col>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Prosječna zapremina trupca'
                          value={`${(supplier.logs && supplier.logs.averageVolume) || 0} ${
                            settings.volumeUnit
                          }`}
                        />
                      </Card>
                    </Col>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Ukupno trupaca'
                          value={(supplier.logs && supplier.logs.total) || 0}
                        />
                      </Card>
                    </Col>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Ukupna zapremina'
                          value={`${(supplier.logs && roundingMethod(supplier.logs.volume)) || 0} ${
                            settings.volumeUnit
                          }`}
                        />
                      </Card>
                    </Col>
                    <Col flex='1 1'>
                      <Card size='small'>
                        <Statistic
                          title='Trupaca prerađeno'
                          value={(supplier.logs && supplier.logs.processed) || 0}
                        />
                      </Card>
                    </Col>
                  </Row>
                  {supplier.logs && Object.keys(supplier.logs.types).length !== 0 ? (
                    <Collapse accordion bordered={true}>
                      {Object.keys(supplier.logs.types).map((type, index) => (
                        <Collapse.Panel
                          key={index}
                          header={
                            <Row>
                              <Col flex='auto'>
                                <Text strong>{type}</Text>
                              </Col>
                              <Col flex='250px'>
                                <Space size='small'>
                                  <Text strong>Prosječna dužina:</Text>
                                  <Text>{`${roundingMethod(
                                    supplier.logs.types[type].averageLength
                                  )} ${settings.logUnit}`}</Text>
                                </Space>
                              </Col>
                              <Col flex='250px'>
                                <Space size='small'>
                                  <Text strong>Prosječan prečnik:</Text>
                                  <Text>{`${roundingMethod(
                                    supplier.logs.types[type].averageRadius
                                  )} ${settings.logUnit}`}</Text>
                                </Space>
                              </Col>
                              <Col flex='250px'>
                                <Space size='small'>
                                  <Text strong>Prosječna zapremina:</Text>
                                  <Text>{`${roundingMethod(
                                    supplier.logs.types[type].averageVolume
                                  )} ${settings.volumeUnit}`}</Text>
                                </Space>
                              </Col>
                              <Col flex='250px'>
                                <Space size='small'>
                                  <Text strong>Ukupno trupaca:</Text>
                                  <Text>{supplier.logs.types[type].total}</Text>
                                </Space>
                              </Col>
                              <Col flex='250px'>
                                <Space size='small'>
                                  <Text strong>Ukupna zapremina:</Text>
                                  <Text>{`${roundingMethod(supplier.logs.types[type].volume)} ${
                                    settings.volumeUnit
                                  }`}</Text>
                                </Space>
                              </Col>
                              <Col flex='150px'>
                                <Space size='small'>
                                  <Text strong>Prerađeno</Text>
                                  <Text>{supplier.logs.types[type].processed}</Text>
                                </Space>
                              </Col>
                            </Row>
                          }>
                          <Table
                            key={index}
                            tableLayout='auto'
                            rowKey='total'
                            dataSource={Object.keys(supplier.logs.types[type].classes).map(
                              (clas) => {
                                return { ...supplier.logs.types[type].classes[clas], class: clas };
                              }
                            )}
                            columns={classColumns}
                            pagination={false}
                            size='small'
                          />
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema trupaca.' />
                  )}
                  {supplier.history && (
                    <>
                      <Row gutter={16}>
                        <Col style={{ alignSelf: 'center' }}>
                          <Text strong>Pregled po</Text>
                        </Col>
                        <Col>
                          <Radio.Group
                            onChange={(e) => setGroupBy(e.target.value)}
                            defaultValue='volume'>
                            <Radio.Button value='volume'>Zapremini trupaca</Radio.Button>
                            <Radio.Button value='total'>Broju trupaca</Radio.Button>
                            <Radio.Button value='processed'>Broju prerađenih trupaca</Radio.Button>
                          </Radio.Group>
                        </Col>
                      </Row>
                      <ReactApexChart
                        options={{
                          chart: {
                            type: 'bar',
                            height: 300,
                            stacked: true,
                            locales: [rs],
                            defaultLocale: 'rs',
                            toolbar: {
                              show: true,
                              offsetX: 0,
                              offsetY: 0,
                              tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true,
                              },
                            },
                            zoom: {
                              enabled: true,
                            },
                          },
                          plotOptions: {
                            bar: {
                              horizontal: false,
                            },
                          },
                          xaxis: {
                            type: 'datetime',
                            categories: dates.map((date) => `${date} GMT`),
                          },
                          tooltip: {
                            y: {
                              formatter: (value) =>
                                `${value} ${!!(value % 1) ? settings.volumeUnit : ''}`,
                            },
                          },
                          legend: {
                            position: 'bottom',
                          },
                          fill: {
                            opacity: 1,
                          },
                        }}
                        series={types.map((type) => {
                          return {
                            name: type,
                            data: dates.reduce((types, date) => {
                              types.push(
                                supplier.history[date].types[type]
                                  ? roundingMethod(supplier.history[date].types[type][groupBy])
                                  : 0
                              );
                              return types;
                            }, []),
                          };
                        })}
                        type='bar'
                        height={300}
                      />
                    </>
                  )}
                </Space>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <PicCenterOutlined />
                    Prerada
                  </span>
                }
                key='3'>
                <Space size={16} direction='vertical' style={{ width: '100%' }}>
                  <Row>
                    <Col flex='1 1 60%'>
                      <Space direction='vertical' style={{ width: '100%' }}>
                        <Row gutter={10}>
                          <Col flex='1 1'>
                            <Card size='small'>
                              <Statistic
                                title='Ukupno elemenata'
                                value={supplier.processing ? supplier.processing.total : 0}
                              />
                            </Card>
                          </Col>
                          <Col flex='1 1'>
                            <Card size='small'>
                              <Statistic
                                title='Ukupna zapremina'
                                value={`${supplier.processing ? supplier.processing.volume : 0} ${
                                  settings.volumeUnit
                                }`}
                              />
                            </Card>
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col flex='1 1'>
                            <Card size='small'>
                              <Statistic
                                title='Iskorišteno'
                                value={`${
                                  supplier.processing ? supplier.processing.efficiency : 0
                                } ${settings.volumeUnit}`}
                              />
                            </Card>
                          </Col>
                          <Col flex='1 1'>
                            <Card size='small'>
                              <Statistic
                                title='Otpad'
                                value={`${supplier.processing ? supplier.processing.trash : 0} ${
                                  settings.volumeUnit
                                }`}
                              />
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Card size='small' style={{ width: '100%' }}>
                            <Statistic
                              title='Iskorištenost'
                              value={`${
                                supplier.processing ? supplier.processing.efficiencyPercentage : 0
                              } %`}
                            />
                          </Card>
                        </Row>
                      </Space>
                    </Col>
                    {supplier.processing && supplier.processing.volume && (
                      <Col flex='1 1 auto'>
                        <Col flex='1 1'>
                          <ReactApexChart
                            series={supplier.processing.pie.series}
                            height='300px'
                            options={{
                              labels: supplier.processing.pie.labels,
                              plotOptions: {
                                pie: {
                                  donut: {
                                    labels: {
                                      show: true,
                                      value: {
                                        formatter: (val) => `${val} ${settings.volumeUnit}`,
                                      },
                                      total: {
                                        show: true,
                                        label: 'Iskorištenost',
                                        formatter: () =>
                                          `${supplier.processing.efficiencyPercentage} %`,
                                      },
                                    },
                                  },
                                },
                              },
                            }}
                            type='donut'
                          />
                        </Col>
                      </Col>
                    )}
                  </Row>
                  {supplier.processing && Object.keys(supplier.processing.types).length !== 0 && (
                    <Collapse accordion bordered={true}>
                      {Object.keys(supplier.processing.types).map((type, index) =>
                        type === companySettings.trashElement ? (
                          <Collapse.Panel
                            showArrow={false}
                            collapsible='disabled'
                            key={index}
                            header={
                              <Row>
                                <Col flex='auto'>
                                  <Text strong>{type}</Text>
                                </Col>
                                <Col flex='250px'>
                                  <Space size='small'>
                                    <Text strong>Ukupna zapremina:</Text>
                                    <Text>{`${supplier.processing.types[type]} ${settings.volumeUnit}`}</Text>
                                  </Space>
                                </Col>
                              </Row>
                            }
                          />
                        ) : (
                          <Collapse.Panel
                            key={index}
                            header={
                              <Row>
                                <Col flex='auto'>
                                  <Text strong>{type}</Text>
                                </Col>
                                <Col flex='250px'>
                                  <Space size='small'>
                                    <Text strong>Ukupno elemenata:</Text>
                                    <Text>{supplier.processing.types[type].total}</Text>
                                  </Space>
                                </Col>
                                <Col flex='250px'>
                                  <Space size='small'>
                                    <Text strong>Ukupna zapremina:</Text>
                                    <Text>{`${supplier.processing.types[type].volume} ${settings.volumeUnit}`}</Text>
                                  </Space>
                                </Col>
                              </Row>
                            }>
                            {supplier.processing.types[type].classes && (
                              <Table
                                key={index}
                                tableLayout='auto'
                                rowKey='total'
                                dataSource={Object.keys(
                                  supplier.processing.types[type].classes
                                ).map((clas) => {
                                  return {
                                    ...supplier.processing.types[type].classes[clas],
                                    class: clas,
                                  };
                                })}
                                columns={elementClassColumns}
                                pagination={false}
                                size='small'
                              />
                            )}
                          </Collapse.Panel>
                        )
                      )}
                    </Collapse>
                  )}
                  {supplier.processingHistory && (
                    <>
                      <Row gutter={16}>
                        <Col style={{ alignSelf: 'center' }}>
                          <Text strong>Pregled po</Text>
                        </Col>
                        <Col>
                          <Radio.Group
                            onChange={(e) => setElementsGroupBy(e.target.value)}
                            defaultValue='volume'>
                            <Radio.Button value='volume'>Zapremini elemenata</Radio.Button>
                            <Radio.Button value='total'>Broju elemenata</Radio.Button>
                          </Radio.Group>
                        </Col>
                      </Row>
                      <ReactApexChart
                        options={{
                          chart: {
                            type: 'bar',
                            height: 300,
                            stacked: true,
                            locales: [rs],
                            defaultLocale: 'rs',
                            toolbar: {
                              show: true,
                              offsetX: 0,
                              offsetY: 0,
                              tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true,
                              },
                            },
                            zoom: {
                              enabled: true,
                            },
                          },
                          plotOptions: {
                            bar: {
                              horizontal: false,
                            },
                          },
                          tooltip: {
                            y: {
                              formatter: (value) =>
                                `${value} ${!!(value % 1) ? settings.volumeUnit : ''}`,
                            },
                          },
                          xaxis: {
                            type: 'datetime',
                            categories: elementDates.map((date) => `${date} GMT`),
                          },
                          legend: {
                            position: 'bottom',
                          },
                          fill: {
                            opacity: 1,
                          },
                        }}
                        series={elementTypes.map((type) => {
                          return {
                            name: type,
                            data: elementDates.reduce((types, date) => {
                              types.push(
                                supplier.processingHistory[date].types[type]
                                  ? roundingMethod(
                                      supplier.processingHistory[date].types[type][elementsGroupBy]
                                    )
                                  : 0
                              );
                              return types;
                            }, []),
                          };
                        })}
                        type='bar'
                        height={300}
                      />
                    </>
                  )}
                </Space>
              </Tabs.TabPane>
            </Tabs>
          </Content>
          <Content className='mainContentBG' style={{ padding: '10px 20px' }}>
            <Tabs type='card' defaultActiveKey='1'>
              <Tabs.TabPane
                tab={
                  <span>
                    <SnippetsOutlined />
                    Prijemnice
                  </span>
                }
                key='1'>
                <Table
                  locale={{
                    emptyText: (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema prijemnica.' />
                    ),
                  }}
                  style={{ marginTop: '20px' }}
                  onRow={(row, rowIndex) => {
                    return {
                      onClick: () => history.push(settings.route.invoice.replace(':id', row.id)),
                    };
                  }}
                  bordered={false}
                  rowClassName='cursorPointer'
                  rowKey='id'
                  showHeader={false}
                  dataSource={invoices}
                  loading={invoicesLoading}
                  pagination={false}
                  columns={columns}
                  size='small'
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <PicCenterOutlined />
                    Liste prerade
                  </span>
                }
                key='2'>
                <Table
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description='Nema listi prerada.'
                      />
                    ),
                  }}
                  style={{ marginTop: '20px' }}
                  onRow={(row, rowIndex) => {
                    return {
                      onClick: () => history.push(settings.route.sawlist.replace(':id', row.id)),
                    };
                  }}
                  bordered={false}
                  rowClassName='cursorPointer'
                  rowKey='id'
                  showHeader={false}
                  dataSource={sawlists}
                  loading={sawlistsLoading}
                  pagination={false}
                  columns={columns}
                  size='small'
                />
              </Tabs.TabPane>
            </Tabs>
          </Content>
        </Space>
      </RouteLayout>
    ) : (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Dobavljač ne postoji' />
    )
  ) : (
    <Spin className='loadingPage' />
  );
};

export default Supplier;
