import { useEffect, useRef } from 'react';
import { socket } from '../utils/api';

const useSawlist = (id, onUpdate) => {
  const callback = useRef(onUpdate);

  useEffect(() => {
    socket.on(`sawlist-${id}`, (message) => {
      console.log(message)
      if (message.caller !== socket.id) {
        callback.current(message.data.type, message.name, message.data.plate);
      }
    });
    return () => {
      socket.off(`sawlist-${id}`);
    };
  }, [id]);
};

export default useSawlist;
