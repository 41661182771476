import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input, message, Modal } from 'antd';
import 'moment/locale/sr';
import React, { useState } from 'react';
import api from '../../utils/api';

const TrashLogModal = ({ children, onOk, ...rest }) => {
  const [visible, setVisible] = useState(false);

  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState(null);
  const [selectedLog, setSelectedLog] = useState(null);

  const trashLog = (id) => {
    const key = 'logTrash';
    setLoading(false);
    api
      .post(`log/edit`, {
        id,
        trashed: true,
      })
      .then((response) => {
        message.success({
          content: `Trupac broj ${response.data.result.plate} uspješno uklonjen sa stanja!`,
          key,
          duration: 5,
        });
        setInput('');
        onOk(false);
        setSelectedLog(null);
        setLogs(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const searchLogs = (plate) => {
    if (plate !== '') {
      setLoading(true);
      api
        .post(`log/search`, { plate }, { params: { group: true } })
        .then((response) => {
          setLogs(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLogs(null);
    }
  };

  const logsArray = logs && [].concat.apply([], Object.values(logs));
  const objSelectedLog =
    logsArray &&
    selectedLog &&
    (logsArray.find((x) => x.id === selectedLog.id) ||
      logsArray.find((x) => x.plate === selectedLog.plate));

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Modal
        confirmLoading={loading}
        visible={visible}
        title='Vraćanje trupca - uklanjanje sa stanja'
        okText='Vrati trupac'
        cancelText='Otkaži'
        okButtonProps={{
          disabled: !objSelectedLog,
        }}
        onCancel={() => setVisible(false)}
        onOk={() => {
          if (!objSelectedLog.sawlist && !objSelectedLog.processed && !objSelectedLog.trashed) {
            trashLog(objSelectedLog.id);
            console.log(objSelectedLog)
          } else {
            message.error({
              content: objSelectedLog.processed
                ? 'Trupac već prerađen!'
                : objSelectedLog.sawlist
                ? 'Trupac već na listi prerade!'
                : 'Trupac već uklonjen sa stanja!',
              duration: 5,
            });
          }
        }}>
        <AutoComplete
          style={{ width: '100%' }}
          notFoundContent='Nema trupaca sa traženom pločicom.'
          options={
            logs &&
            Object.keys(logs).map((type) => {
              return {
                label: type,
                options: logs[type].map((log) => {
                  return {
                    key: log.id,
                    label: log.plate,
                    value: log.plate,
                  };
                }),
              };
            })
          }
          value={input}
          onChange={(value) => {
            setInput(value);
            searchLogs(value);
          }}
          onSelect={(value, option) => {
            if (option) {
              setSelectedLog({ id: option.key, plate: value });
            }
          }}
          backfill
          allowClear
          filterOption={(inputValue, option) =>
            option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }>
          <Input
            style={{ width: '100%' }}
            prefix={<SearchOutlined />}
            placeholder='Pretražite trupce'
          />
        </AutoComplete>
      </Modal>
    </>
  );
};

export default TrashLogModal;
