import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Descriptions,
  Table,
  message,
  Popconfirm,
  Empty,
  Row,
  Col,
  Tag,
  Tooltip,
  Space,
} from 'antd';
import RouteLayout from '../../components/RouteLayout';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import settings from '../../settings';
import ProcessLogModal from './modals/ProcessLogModal';
import ElementsModal from './modals/ElementsModal';
import useSawlist from '../../socketio/useSawlist';
import { LockTwoTone, UnlockTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

const Sawlist = () => {
  let { id } = useParams();
  const history = useHistory();
  const [sawlist, setSawlist] = useState(false);
  const [loading, setLoading] = useState(true);

  const getSawlist = useCallback(
    (setload = true) => {
      if (setload) {
        setLoading(true);
      }
      api
        .post(`sawlist/get`, { id })
        .then((response) => {
          setLoading(false);
          setSawlist({
            ...response.data,
            logs: response.data.logs.map((log, index) => {
              return { ...log, number: response.data.logs.length - index };
            }),
          });
        })
        .catch((error) => {
          setLoading(false);
          history.push(settings.route.sawlists);
          console.log(error);
        });
    },
    [id, history]
  );

  const onUnprocessLog = (log) => {
    let key = 'sawlistProcessing';
    message.loading({ content: 'Uklanjanje trupca...', key });
    api
      .post(`sawlist/unprocess`, { id: log, sawlist: id })
      .then((response) => {
        getSawlist(true);
        message.success({
          content: `Trupac broj ${response.data.result.plate} uklonjen sa liste prerade!`,
          key,
          duration: 5,
        });
      })
      .catch(() => {
        message.error({
          content: 'Greška pri uklanjanju trupca.',
          key,
          duration: 5,
        });
      });
  };

  const onDelete = (id) => {
    let key = 'sawlistDeletion';
    message.loading({ content: 'Brisanje liste prerade...', key });
    api
      .post(`sawlist/delete`, { id })
      .then((response) => {
        history.push(settings.route.sawlists);
        message.success({
          content: `Lista prerade dobavljača ${response.data.result.supplier.name} obrisana!`,
          key,
          duration: 5,
        });
      })
      .catch(() => {
        message.error({
          content: 'Greška pri brisanju liste prerade.',
          key,
          duration: 5,
        });
      });
  };

  const onLockToggle = (locked) => {
    let key = 'sawlistLock';
    message.loading({
      content: `${locked ? 'Otključavanje' : 'Zaključavanje'} liste prerade...`,
      key,
    });
    setLoading(true);
    api
      .post(`sawlist/edit`, {
        id: sawlist.id,
        locked: !locked,
      })
      .then((response) => {
        message.success({
          content: `Lista prerade dobavljača ${response.data.result.supplier.name} uspješno ${
            locked ? 'otključana' : 'zaključana'
          }!`,
          key,
          duration: 5,
        });
        getSawlist(true);
      })
      .catch(() => {
        message.error({
          content: `Greška pri ${locked ? 'otključavanju' : 'zaključavanju'} liste prerade.`,
          key,
          duration: 5,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getSawlist();
  }, [getSawlist]);

  const onUpdate = (event, name, extra) => {
    if (event === 'process') {
      message.info({
        content: `${name} je upravo preradio trupac broj ${extra}.`,
        duration: 3,
      });
    } else if (event === 'unprocess') {
      message.warning({
        content: `${name} je upravo obrisao trupac broj ${extra} sa liste prerade!`,
        duration: 3,
      });
    } else if (event === 'edit') {
      message.info({
        content: `${name} je upravo izmjenio ovu listu prerade!`,
        duration: 3,
      });
    } else if (event === 'elements') {
      message.success({
        content: `${name} je upravo unio elemente u ovu listu prerade!`,
        duration: 3,
      });
    } else if (event === 'delete') {
      message.warning({
        content: `${name} je upravo obrisao ovu listu prerade!`,
        duration: 3,
      });
    }
    getSawlist(false);
  };

  useSawlist(id, onUpdate);

  const columns = [
    {
      title: 'Redni broj',
      dataIndex: 'number',
      key: 'number',
      width: '10%',
      render: (value) => `${value}.`,
      sorter: (a, b) => a.number - b.number,
    },
    {
      title: 'Vrsta',
      dataIndex: 'type',
      key: 'type',
      filters:
        sawlist &&
        sawlist.logs &&
        [...new Set(sawlist.logs.map((item) => item.type))].map((type) => {
          return { value: type, text: type };
        }),
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
    {
      title: 'Klasa',
      dataIndex: 'class',
      filters:
        sawlist &&
        sawlist.logs &&
        [...new Set(sawlist.logs.map((item) => item.class))].map((clas) => {
          return { value: clas, text: clas };
        }),
      key: 'class',
      onFilter: (value, record) => record.class.indexOf(value) === 0,
    },
    {
      title: 'Broj pločice',
      dataIndex: 'plate',
      key: 'plate',
      sorter: (a, b) => a.plate.replace(/\D/g, '') - b.plate.replace(/\D/g, ''),
    },
    {
      title: `Dužina (${settings.logUnit})`,
      dataIndex: 'length',
      key: 'length',
      sorter: (a, b) => a.length - b.length,
    },
    {
      title: `Prečnik (${settings.logUnit})`,
      dataIndex: 'radius',
      key: 'radius',
      sorter: (a, b) => a.radius - b.radius,
    },
    {
      title: `Zapremina (${settings.volumeUnit})`,
      dataIndex: 'volume',
      key: 'volume',
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: 'Akcija',
      dataIndex: 'id',
      key: 'action',
      align: 'center',
      width: 'min-content',
      render: (id, row) => (
        <Popconfirm
          title='Da li ste sigurni da želite da obrišete trupac sa liste prerade?'
          onConfirm={() => onUnprocessLog(id)}
          okText='Obriši'
          placement='topLeft'
          okButtonProps={{ danger: true }}
          cancelText='Otkaži'>
          <Button type='link'>Obriši</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <RouteLayout
      title={
        !loading ? (
          <Row gutter={20} align='middle'>
            <Col>Lista prerade</Col>
            <Col>
              {sawlist.locked ? (
                <Tooltip title={`Zaključano ${moment(sawlist.lockedAt).format(settings.fullDate)}`}>
                  <Tag
                    icon={<LockTwoTone twoToneColor='#585858' />}
                    color='default'
                    className='cursorPointer'>
                    Zaključano
                  </Tag>
                </Tooltip>
              ) : (
                <Tag icon={<UnlockTwoTone twoToneColor='#52C41A' />} color='success'>
                  Otključano
                </Tag>
              )}
            </Col>
          </Row>
        ) : (
          'Lista prerade'
        )
      }
      routes={[
        {
          path: settings.route.home,
          breadcrumbName: 'Foresta',
        },
        { path: settings.route.sawlists, breadcrumbName: 'Prerada' },
        {
          breadcrumbName: 'Lista prerade',
        },
      ]}
      extra={
        !loading &&
        sawlist && [
          <Space direction='horizontal' size={10} key='actions'>
            {!sawlist.locked && (
              <Popconfirm
                title='Da li ste sigurni da želite da obrišete listu prerade?'
                onConfirm={() =>
                  sawlist.locked
                    ? message.error({
                        content: 'Nije moguće obrisati zaključanu listu prerade.',
                        duration: 5,
                      })
                    : onDelete(id)
                }
                okText='Obriši'
                placement='rightTop'
                okButtonProps={{ danger: true }}
                cancelText='Otkaži'>
                <Button type='secondary'>Obriši</Button>
              </Popconfirm>
            )}
            <Popconfirm
              title={`Da li ste sigurni da želite da ${
                sawlist.locked ? 'otključate' : 'zaključate'
              } listu prerade?${
                !sawlist.locked ? ' Nećete više moći mijenjati listu prerade.' : ''
              }`}
              onConfirm={() => onLockToggle(sawlist.locked)}
              okText={sawlist.locked ? 'Otključaj' : 'Zaključaj'}
              placement='rightTop'
              okButtonProps={{ danger: true }}
              cancelText='Otkaži'>
              <Button type='secondary'>{sawlist.locked ? 'Otključaj' : 'Zaključaj'}</Button>
            </Popconfirm>
            <ElementsModal key={sawlist.locked} sawlist={sawlist} onOk={getSawlist}>
              <Button type='primary'>Elementi</Button>
            </ElementsModal>
            {!sawlist.locked && (
              <ProcessLogModal
                name={sawlist.supplier.name}
                sawlist={id}
                key='process'
                onOk={getSawlist}>
                <Button type='primary'>Dodaj trupac na listu prerade</Button>
              </ProcessLogModal>
            )}
          </Space>,
        ]
      }
      descriptions={
        !loading && (
          <Descriptions size='middle' column={4}>
            <Descriptions.Item label={<Text strong>Dobavljač</Text>}>
              <Text
                type='success'
                className='cursorPointer'
                onClick={() =>
                  sawlist.supplier &&
                  history.push(settings.route.supplier.replace(':id', sawlist.supplier.id))
                }>
                {sawlist.supplier ? sawlist.supplier.name : '-'}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Datum</Text>}>
              {sawlist.date && moment(sawlist.date).format(settings.date)}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Ukupno trupaca</Text>}>
              {`${sawlist.total}`}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Ukupna zapremina</Text>}>
              {`${sawlist.volume} ${settings.volumeUnit}`}
            </Descriptions.Item>
          </Descriptions>
        )
      }>
      <Table
        tableLayout='auto'
        rowKey='id'
        loading={loading}
        dataSource={sawlist.logs}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='Nema trupaca u listi prerade.'
            />
          ),
        }}
        columns={columns}
        pagination={false}
        size='small'
      />
    </RouteLayout>
  );
};

export default Sawlist;
