import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Form, Modal, DatePicker, Col, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/sr';
import locale from 'antd/es/date-picker/locale/sr_RS';
import Text from 'antd/lib/typography/Text';
import api from '../../../utils/api';
import settings from '../../../settings';

const EditInvoiceModal = ({ children, onOk, invoice, ...rest }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [suppliers, setSuppliers] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (visible) {
      api
        .get(`suppliers`, { params: { group: true } })
        .then((response) => {
          setSuppliers(response.data.suppliers);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
      api
        .get(`users`)
        .then((response) => {
          setUsers(response.data.users);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [visible]);

  const onFinish = (values) => {
    let key = 'invoiceEdit';
    message.loading({ content: 'Izmjena prijemnice...', key });
    setLoading(true);
    api
      .post(`invoice/edit`, {
        id: invoice.id,
        date: values.date.toISOString(),
        supplier: values.supplierid,
        measurer: values.measurerid,
      })
      .then((response) => {
        message.success({
          content: `Prijemnica broj ${response.data.result.number} uspješno izmjenjena!`,
          key,
          duration: 5,
        });
        setVisible(false);
        onOk();
      })
      .catch(() => {
        message.error({
          content: 'Greška pri izmjeni prijemnice.',
          key,
          duration: 5,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      {invoice.measurer && (
        <Modal
          confirmLoading={loading}
          visible={visible}
          title='Izmjena prijemnice'
          okText='Izmjeni prijemnicu'
          cancelText='Otkaži'
          onCancel={() => setVisible(false)}
          onOk={() =>
            invoice.locked
              ? message.error({
                  content: 'Nije moguće izmjeniti zaključanu prijemnicu.',
                  duration: 5,
                })
              : form.submit()
          }>
          <Form
            layout='vertical'
            size='middle'
            requiredMark={false}
            onFinish={onFinish}
            form={form}>
            <Form.Item initialValue={invoice.supplier.id} name='supplierid' noStyle>
              <Input type='hidden' />
            </Form.Item>
            <Form.Item name='measurerid' initialValue={invoice.measurer.id} noStyle>
              <Input type='hidden' />
            </Form.Item>
            <Form.Item
              name='supplier'
              label='Ime dobavljača'
              initialValue={invoice.supplier.name}
              rules={[
                {
                  required: true,
                  message: 'Odaberite dobavljača!',
                },
              ]}>
              <AutoComplete
                notFoundContent='Ne postoji traženi dobavljač.'
                options={
                  suppliers &&
                  Object.keys(suppliers).map((letter) => {
                    return {
                      label: letter,
                      options: suppliers[letter].map((supplier) => {
                        return {
                          key: supplier.id,
                          label: (
                            <Row>
                              <Col flex='auto'>
                                <Text strong>{supplier.name}</Text>
                              </Col>
                              <Col flex='20%'>
                                <Text>{supplier.tax ? 'Pravno lice' : 'Fizičko lice'}</Text>
                              </Col>
                            </Row>
                          ),
                          value: supplier.name,
                        };
                      }),
                    };
                  })
                }
                onChange={(_, option) =>
                  form.setFields([{ name: 'supplierid', value: option && option.key }])
                }
                allowClear
                filterOption={(inputValue, option) =>
                  option.value &&
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }>
                <Input prefix={<SearchOutlined />} placeholder='Pretražite dobavljače' />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              name='measurer'
              label='Mjerio'
              initialValue={invoice.measurer.name}
              rules={[
                {
                  required: true,
                  message: 'Odaberite korisnika!',
                },
              ]}>
              <AutoComplete
                notFoundContent='Ne postoji traženi korisnik.'
                options={
                  users &&
                  users.map((user) => {
                    return {
                      key: user.id,
                      value: user.name,
                    };
                  })
                }
                onChange={(_, option) =>
                  form.setFields([{ name: 'measurerid', value: option && option.key }])
                }
                allowClear
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }>
                <Input prefix={<SearchOutlined />} placeholder='Pretražite radnike' />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              name='date'
              label='Datum'
              initialValue={moment(invoice.date)}
              rules={[
                {
                  required: true,
                  message: 'Odaberite datum!',
                },
              ]}>
              <DatePicker style={{ width: '100%' }} locale={locale} format={settings.date} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditInvoiceModal;
