import { useEffect, useRef } from 'react';
import { socket } from '../utils/api';

const useSetting = (onUpdate) => {
  const callback = useRef(onUpdate);

  useEffect(() => {
    socket.on(`setting-edit`, (message) => {
      if (message.caller !== socket.id) {
        callback.current(message.name);
      }
    });
  }, []);
};

export default useSetting;
