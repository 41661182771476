import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  Form,
  Modal,
  Col,
  Row,
  Table,
  Divider,
  Statistic,
  Card,
  InputNumber,
  Empty,
  Select,
  Button,
  Dropdown,
  Menu,
  Popconfirm,
  Space,
  message,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import settings from '../../../settings';
import api from '../../../utils/api';
import calculateDimensionVolume from '../../../utils/calculateDimensionVolume';
import roundingDimensionMethod from '../../../utils/roundingDimensionMethod';

const ElementsModal = ({ children, onOk, sawlist, ...rest }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [companySettings] = useGlobal('settings');
  const [processing, setProcessing] = useState(sawlist.processing || {});

  const sawlistVolume = sawlist.volume || 0.0;

  const updateProcessing = (newProcessing) => {
    setProcessing(newProcessing);
    recalculate(newProcessing);
  };

  const addType = (type) =>
    setProcessing({
      ...processing,
      elements: {
        [type]: companySettings.elements[type] ? {} : null,
        ...processing.elements,
      },
    });

  const addClass = (type, clas) =>
    setProcessing({
      ...processing,
      elements: {
        ...processing.elements,
        [type]: {
          ...processing.elements[type],
          classes: processing.elements[type].classes.concat({ class: clas }),
        },
      },
    });

  const removeType = (type) => {
    const temp = processing.elements;
    delete temp[type];
    updateProcessing({
      ...processing,
      elements: temp,
    });
  };

  const removeClass = (type, clas) =>
    updateProcessing({
      ...processing,
      elements: {
        ...processing.elements,
        [type]: {
          ...processing.elements[type],
          classes: processing.elements[type].classes.filter((item) => item.class !== clas),
        },
      },
    });

  const onFieldChange = (value) => {
    var newProcessing = {};
    if (value[0]) {
      const [type, clas, index, field] = value[0].name[0].split('|');

      if (clas) {
        const classIndex = processing.elements[type].classes.findIndex(
          (x, i) => x.class === clas && i === +index
        );
        newProcessing = {
          ...processing,
          elements: {
            ...processing.elements,
            [type]: {
              ...processing.elements[type],
              classes: Object.assign([...processing.elements[type].classes], {
                [classIndex]: {
                  ...processing.elements[type].classes[classIndex],
                  [field]: value[0].value,
                },
              }),
            },
          },
        };

        updateProcessing(newProcessing);
      } else {
        newProcessing = {
          ...processing,
          elements: {
            ...processing.elements,
            [type]: value[0].value,
          },
        };
        updateProcessing(newProcessing);
      }
    }
  };

  const recalculate = (processing) => {
    let elements = JSON.parse(JSON.stringify(processing.elements));
    let totalvolume = 0;
    const types = Object.keys(elements);
    for (let i = 0; i < types.length; ++i) {
      const type = types[i];
      // If true then calculate dimension, else don't. Or if its unknown but an object still calculate.
      if (companySettings.elements[type] || elements[type]?.constructor === Object) {
        const typeObj = elements[type];
        let typevolume = 0;
        typeObj.classes.forEach((clas) => {
          const classVolume = roundingDimensionMethod(
            calculateDimensionVolume(clas.height, clas.width, clas.length) * clas.amount
          );
          clas.volume = classVolume;
          typevolume += classVolume;
        });
        totalvolume += typevolume;
        elements[type].volume = roundingDimensionMethod(typevolume);
      } else {
        const volume = roundingDimensionMethod(elements[type]);
        totalvolume += volume;
      }
    }

    setProcessing({
      volume: roundingDimensionMethod(totalvolume),
      difference: roundingDimensionMethod(Math.abs((sawlistVolume || 0) - totalvolume)),
      elements: elements,
    });
  };

  const onSave = () => {
    let key = 'sawlistElements';
    message.loading({ content: 'Čuvanje elemenata liste prerade...', key });
    setLoading(true);
    api
      .post(`sawlist/elements`, {
        id: sawlist.id,
        elements: processing.elements,
      })
      .then((response) => {
        message.success({
          content: `Prerada trupaca dobavljača ${response.data.result.supplier.name} uspješno sačuvana!`,
          key,
          duration: 5,
        });
        setVisible(false);
        setLoading(false);
        onOk();
      })
      .catch(() => {
        message.error({
          content: 'Greška pri čuvanju elemenata.',
          key,
          duration: 5,
        });
        setLoading(false);
      });
  };

  const addableElements =
    processing && processing.elements && companySettings.elements
      ? Object.keys(companySettings.elements).filter(
          (x) => !Object.keys(processing.elements).includes(x)
        )
      : Object.keys(companySettings.elements);

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>

      <Modal
        {...rest}
        confirmLoading={loading}
        visible={visible}
        style={{ top: 50 }}
        title={`Prerada dobavljača ${sawlist.supplier.name}`}
        onCancel={() => setVisible(false)}
        width='70%'
        footer={
          !sawlist.locked && [
            <Space key='1' size='small'>
              <Button onClick={() => setVisible(false)}>Otkaži</Button>
              {sawlistVolume < processing.volume ? (
                <Popconfirm
                  title={`Prekoračili ste ukupnu zapreminu trupaca! Iskorištenost je ${Math.round(
                    (processing.volume / sawlistVolume) * 100
                  )}%. Da li ste sigurni da želite da nastavite?`}
                  okText='Sačuvaj'
                  onConfirm={() => form.submit()}
                  placement='topLeft'
                  okButtonProps={{
                    danger: true,
                    disabled:
                      !processing.elements ||
                      (processing.elements && Object.keys(processing.elements).length === 0),
                  }}
                  cancelText='Otkaži'>
                  <Button type='primary'>Sačuvaj</Button>
                </Popconfirm>
              ) : (
                <Button
                  type='primary'
                  disabled={
                    !processing.elements ||
                    (processing.elements && Object.keys(processing.elements).length === 0)
                  }
                  onClick={() => form.submit()}>
                  Sačuvaj
                </Button>
              )}
            </Space>,
          ]
        }>
        {processing && visible ? (
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            <Card>
              <Row gutter={16}>
                <Col flex='1 1'>
                  <Statistic
                    title='Ukupna zapremina trupaca'
                    value={`${sawlistVolume} ${settings.volumeUnit}`}
                  />
                </Col>
                <Col flex='1 1'>
                  <Statistic
                    title='Prerađeno'
                    value={`${processing.volume || 0} ${settings.volumeUnit}`}
                  />
                </Col>
                <Col flex='1 1'>
                  <Statistic
                    title={
                      sawlistVolume < processing.volume ? (
                        <Space size='small'>
                          <span>Prekoračenje</span>
                          <ExclamationCircleFilled />
                        </Space>
                      ) : (
                        'Preostalo'
                      )
                    }
                    value={`${
                      processing.difference !== undefined ? processing.difference : sawlistVolume
                    } ${settings.volumeUnit}`}
                  />
                </Col>
              </Row>
            </Card>
            {processing.elements && Object.keys(processing.elements).length !== 0 ? (
              <div className='modalScrollableContainerSawlist'>
                <Form
                  layout='vertical'
                  size='middle'
                  requiredMark={false}
                  form={form}
                  onFinish={() => onSave()}
                  onFinishFailed={() =>
                    message.error({
                      content: 'Unesite sva polja!',
                      duration: 5,
                    })
                  }
                  onFieldsChange={(value) => onFieldChange(value)}>
                  {Object.keys(processing.elements).map((type, index) => {
                    if (companySettings.elements[type]) {
                      let typeObj = processing.elements[type] || {};
                      typeObj.classes = typeObj.classes || [];
                      typeObj.volume = typeObj.volume || 0;
                      return (
                        <React.Fragment key={index}>
                          <Divider orientation='left'>
                            {`${type} (${typeObj.volume} ${settings.volumeUnit})`}
                            {!sawlist.locked && (
                              <Popconfirm
                                title='Da li ste sigurni da želite da obrišete element?'
                                onConfirm={() => removeType(type)}
                                okText='Obriši'
                                placement='rightTop'
                                okButtonProps={{ danger: true }}
                                cancelText='Otkaži'>
                                <Button type='link'>Obriši</Button>
                              </Popconfirm>
                            )}
                          </Divider>
                          <Table
                            size='small'
                            dataSource={typeObj.classes}
                            rowKey={(row, index) => {
                              return `${type}|${row.class}|${index}|class`;
                            }}
                            locale={{ emptyText: <Text type='secondary'>Nema klasa</Text> }}
                            summary={() =>
                              !sawlist.locked && (
                                <Table.Summary.Row className='summaryRow'>
                                  <Table.Summary.Cell colSpan={7}>
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          {companySettings.elementClasses.map((clas, index) => (
                                            <Menu.Item
                                              key={index}
                                              onClick={() => addClass(type, clas)}>
                                              {clas}
                                            </Menu.Item>
                                          ))}
                                        </Menu>
                                      }
                                      trigger={['click']}>
                                      <Button style={{ width: '100%' }} type='dashed'>
                                        Dodaj klasu
                                      </Button>
                                    </Dropdown>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              )
                            }
                            pagination={false}
                            columns={[
                              {
                                title: 'Klasa',
                                dataIndex: 'class',
                                key: 'class',
                                width: '10%',
                                render: (clas, row, index) =>
                                  sawlist.locked ? (
                                    clas
                                  ) : (
                                    <Form.Item
                                      noStyle
                                      name={`${type}|${row.class}|${index}|class`}
                                      initialValue={clas}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Odaberite klasu elementa.',
                                        },
                                      ]}>
                                      <Select
                                        placeholder='Odaberite klasu elementa'
                                        style={{ width: '100%' }}
                                        options={
                                          companySettings.elementClasses &&
                                          companySettings.elementClasses.map((clas) => {
                                            return { value: clas, label: clas };
                                          })
                                        }
                                      />
                                    </Form.Item>
                                  ),
                              },
                              {
                                title: 'Debljina (mm)',
                                dataIndex: 'height',
                                key: 'height',
                                width: '20%',
                                render: (value, row, index) =>
                                  sawlist.locked ? (
                                    value
                                  ) : (
                                    <Form.Item
                                      noStyle
                                      name={`${type}|${row.class}|${index}|height`}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Unesite debljinu.',
                                        },
                                      ]}
                                      initialValue={value}>
                                      <InputNumber
                                        placeholder='Debljina'
                                        min={0}
                                        precision={settings.processingDecimals}
                                        max={10000}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  ),
                              },
                              {
                                title: 'Širina (mm)',
                                dataIndex: 'width',
                                key: 'width',
                                width: '20%',
                                render: (value, row, index) =>
                                  sawlist.locked ? (
                                    value
                                  ) : (
                                    <Form.Item
                                      noStyle
                                      name={`${type}|${row.class}|${index}|width`}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Unesite širinu.',
                                        },
                                      ]}
                                      initialValue={value}>
                                      <InputNumber
                                        placeholder='Širina'
                                        min={0}
                                        precision={settings.processingDecimals}
                                        max={10000}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  ),
                              },
                              {
                                title: 'Dužina (mm)',
                                dataIndex: 'length',
                                key: 'length',
                                width: '20%',
                                render: (value, row, index) =>
                                  sawlist.locked ? (
                                    value
                                  ) : (
                                    <Form.Item
                                      noStyle
                                      name={`${type}|${row.class}|${index}|length`}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Unesite dužinu.',
                                        },
                                      ]}
                                      initialValue={value}>
                                      <InputNumber
                                        placeholder='Dužina'
                                        min={0}
                                        precision={settings.processingDecimals}
                                        max={10000}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  ),
                              },
                              {
                                title: 'Količina',
                                dataIndex: 'amount',
                                width: '15%',
                                key: 'amount',
                                render: (value, row, index) =>
                                  sawlist.locked ? (
                                    value
                                  ) : (
                                    <Form.Item
                                      noStyle
                                      name={`${type}|${row.class}|${index}|amount`}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Unesite količinu.',
                                        },
                                      ]}
                                      initialValue={value}>
                                      <InputNumber
                                        placeholder='Količina'
                                        min={0}
                                        precision={0}
                                        max={10000}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  ),
                              },
                              {
                                title: 'Zapremina',
                                dataIndex: 'volume',
                                width: '15%',
                                key: 'volume',
                                render: (value, row, index) =>
                                  sawlist.locked ? (
                                    value
                                  ) : (
                                    <Form.Item
                                      noStyle
                                      name={`${type}|${row.class}|${index}|volume|${value}`}
                                      initialValue={value}>
                                      <InputNumber
                                        precision={settings.dimensionVolumeDecimals}
                                        readOnly
                                        min={0}
                                        value={value}
                                        formatter={(value) =>
                                          `${value || 0} ${settings.volumeUnit}`
                                        }
                                        max={10000}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  ),
                              },
                              {
                                dataIndex: 'class',
                                width: 'min-content',
                                key: 'action',
                                render: (value) =>
                                  !sawlist.locked && (
                                    <Popconfirm
                                      title='Da li ste sigurni da želite da obrišete klasu elementa?'
                                      onConfirm={() => removeClass(type, value)}
                                      okText='Obriši'
                                      placement='top'
                                      okButtonProps={{ danger: true }}
                                      cancelText='Otkaži'>
                                      <Button size='small'>X</Button>
                                    </Popconfirm>
                                  ),
                              },
                            ]}
                          />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={index}>
                          <Divider orientation='left'>
                            {type}
                            {sawlist.locked ? (
                              ` (${processing.elements[type]} ${settings.volumeUnit})`
                            ) : (
                              <Popconfirm
                                title='Da li ste sigurni da želite da obrišete element?'
                                onConfirm={() => removeType(type)}
                                okText='Obriši'
                                placement='top'
                                okButtonProps={{ danger: true }}
                                cancelText='Otkaži'>
                                <Button type='link'>Obriši</Button>
                              </Popconfirm>
                            )}
                          </Divider>
                          {!sawlist.locked && (
                            <Form.Item
                              noStyle
                              name={`${type}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Unesite zapreminu.',
                                },
                              ]}
                              initialValue={processing.elements[type]}>
                              <InputNumber
                                placeholder={`Unesite zapreminu u ${settings.volumeUnit}`}
                                min={0}
                                precision={settings.dimensionVolumeDecimals}
                                max={100}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          )}
                        </React.Fragment>
                      );
                    }
                  })}
                </Form>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {addableElements && addableElements.length !== 0 && !sawlist.locked && (
              <Dropdown
                overlay={
                  <Menu>
                    {addableElements.map((element, index) => (
                      <Menu.Item key={index} onClick={() => addType(element)}>
                        {element}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}>
                <Button style={{ width: '100%' }} type='dashed'>
                  Dodaj element
                </Button>
              </Dropdown>
            )}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Modal>
    </>
  );
};

export default ElementsModal;
