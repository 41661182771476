const settings = {
  baseUrl: 'https://api.cartasystems.com/',
  proccessingDecimals: 1,
  logDecimals: 2,
  dimensionVolumeDecimals: 3,
  date: 'DD.MM.YYYY.',
  fullDate: 'DD.MM.YYYY. u HH:mm',
  currency: 'KM',
  logUnit: 'cm',
  volumeUnit: 'm³',
  route: {
    home: '/',
    invoices: '/prijemnice',
    invoice: '/prijemnica/:id',
    suppliers: '/dobavljaci',
    supplier: '/dobavljac/:id',
    stock: '/stanje',
    stocklist: '/stanje/lista',
    trash: '/vraceni',
    trashlist: '/vraceni/lista',
    history: '/istorija',
    historylist: '/istorija/lista',
    loginfo: '/informacije/:id?',
    sawlists: '/prerada',
    sawlist: '/prerada/lista/:id',
    element: '/elementi',
    elementlist: '/elementi/lista',
    admin: '/podesavanja',
    users: '/korisnici',
  },
};

export default settings;
