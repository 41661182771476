import {
  MailOutlined,
  PhoneOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, Input, message, Modal, Switch } from 'antd';
import 'moment/locale/sr';
import React, { useState } from 'react';
import api from '../../../utils/api';

const AddSupplierModal = ({ children, onOk, ...rest }) => {
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    let key = 'supplierCreation';
    message.loading({ content: 'Dodavanje dobavljača...', key });
    setLoading(true);
    api
      .post(`supplier/add`, {
        name: values.name,
        phone: values.phone,
        email: values.email,
        address: values.address,
        tax: values.tax,
      })
      .then((response) => {
        message.success({
          content: `Dobavljač ${response.data.result.name} uspješno dodan!`,
          key,
          duration: 5,
        });
        setVisible(false);
        form.resetFields();
        onOk();
        setLoading(false);
      })
      .catch((error) => {
        form.setFields([
          {
            name: 'name',
            errors: ['Dobavljač već postoji!'],
          },
        ]);

        message.error({ content: 'Greška pri dodavanju dobavljača.', key, duration: 5 });
        setLoading(false);
      });
  };

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Modal
        confirmLoading={loading}
        visible={visible}
        title='Novi dobavljač'
        okText='Dodaj dobavljača'
        cancelText='Otkaži'
        onCancel={() => setVisible(false)}
        onOk={() => {
          form.submit();
        }}>
        <Form layout='vertical' size='middle' onFinish={onFinish} requiredMark={false} form={form}>
          <Form.Item
            name='name'
            label='Ime dobavljača'
            rules={[
              {
                required: true,
                message: 'Unesite ime dobavljača!',
              },
            ]}>
            <Input
              type='text'
              style={{ width: '100%' }}
              prefix={<UserOutlined />}
              placeholder='Unesite ime dobavljača'
            />
          </Form.Item>
          <Form.Item name='phone' label='Telefon'>
            <Input
              type='tel'
              style={{ width: '100%' }}
              prefix={<PhoneOutlined />}
              placeholder='Unesite kontakt telefon'
            />
          </Form.Item>
          <Form.Item name='email' label='Email'>
            <Input
              type='email'
              style={{ width: '100%' }}
              prefix={<MailOutlined />}
              placeholder='Unesite email'
            />
          </Form.Item>
          <Form.Item name='address' label='Adresa'>
            <Input
              type='text'
              style={{ width: '100%' }}
              prefix={<SendOutlined />}
              placeholder='Unesite adresu'
            />
          </Form.Item>
          <Form.Item name='tax' label='Vrsta dobavljača'>
            <Switch
              checkedChildren='Pravno lice ( SA PDV-om )'
              unCheckedChildren='Fizičko lice ( BEZ PDV-a )'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddSupplierModal;
