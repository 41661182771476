import { useEffect, useRef } from 'react';
import { socket } from '../utils/api';

const useLog = (id, onUpdate) => {
  const callback = useRef(onUpdate);

  useEffect(() => {
    socket.on(`log-${id}`, (message) => {
      if (message.caller !== socket.id) {
        callback.current(message.data.type, message.name);
      }
    });

    return () => {
      socket.off(`log-${id}`);
    };
  }, [id]);
};

export default useLog;
