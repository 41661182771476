import React from 'react';
import { Space, Col, Row } from 'antd';
import { useGlobal } from 'reactn';
import RouteLayout from '../components/RouteLayout';
import settings from '../settings';
import SortableTable from '../components/SortableTable';

const Admin = () => {
  const [companySettings, setCompanySettings] = useGlobal('settings');

  return (
    <RouteLayout
      title='Podešavanja'
      showBG={false}
      routes={[
        {
          path: settings.route.home,
          breadcrumbName: 'Foresta',
        },
        {
          breadcrumbName: 'Podešavanja',
        },
      ]}>
      <Space direction='vertical' size={20} style={{ width: '100%' }}>
        <Row gutter={20}>
          <Col flex='1 1'>
            <SortableTable
              setData={(data) =>
                setCompanySettings({ ...companySettings, types: data.map((arr) => arr.type) })
              }
              data={companySettings.types.map((type, index) => {
                return { type, index, key: index };
              })}
              title='Vrste trupca'
              name='vrstu'
              field='type'
              columns={[
                {
                  dataIndex: 'type',
                  title: 'Vrsta',
                  className: 'drag-visible',
                  editable: true,
                },
              ]}
            />
          </Col>
          <Col flex='1 1'>
            <SortableTable
              setData={(data) =>
                setCompanySettings({ ...companySettings, classes: data.map((arr) => arr.clas) })
              }
              data={companySettings.classes.map((clas, index) => {
                return { clas, index, key: index };
              })}
              field='clas'
              title='Klase trupca'
              name='klasu'
              columns={[
                {
                  dataIndex: 'clas',
                  title: 'Klasa',
                  className: 'drag-visible',
                },
              ]}
            />
          </Col>
        </Row>
      </Space>
    </RouteLayout>
  );
};

export default Admin;
