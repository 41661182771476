import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Empty,
  message,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import RouteLayout from '../../components/RouteLayout';
import { LockTwoTone, UnlockTwoTone } from '@ant-design/icons';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import AddInvoiceModal from './modals/AddInvoiceModal';
import api from '../../utils/api';
import { useHistory } from 'react-router-dom';
import settings from '../../settings';
import locked from '../../img/locked.svg';
import unlocked from '../../img/unlocked.svg';
import EditInvoiceModal from './modals/EditInvoiceModal';
import useInvoices from '../../socketio/useInvoices';
import { useQuery } from '../../utils/useQuery';

const Invoices = () => {
  const history = useHistory();
  const params = useQuery();
  const [invoices, setInvoices] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({
    page: +params.get('strana') > 0 ? +params.get('strana') - 1 : 0,
    perPage: 10,
  });

  const getInvoices = useCallback(
    (loading = true) => {
      setLoading(loading);
      window.history.replaceState(
        null,
        null,
        `${settings.route.invoices}?strana=${query.page + 1}`
      );
      api
        .get(`invoices`, { params: { ...query, group: true } })
        .then((response) => {
          setInvoices({ data: response.data.invoices, total: response.data.total });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [query]
  );

  useEffect(() => getInvoices(), [getInvoices]);

  const onUpdate = (event, name, extra) => {
    if (event === 'edit') {
      message.info({
        content: `${name} je upravo izmjenio prijemnicu broj ${extra}.`,
        duration: 3,
      });
    } else if (event === 'delete') {
      message.warning({
        content: `${name} je upravo obrisao prijemnicu broj ${extra}!`,
        duration: 3,
      });
    } else if (event === 'add') {
      message.success({
        content: `${name} je upravo dodao prijemnicu broj ${extra}.`,
        duration: 3,
      });
    }
    getInvoices(false);
  };

  useInvoices(onUpdate);

  const onDelete = (id) => {
    let key = 'invoiceDeletion';
    message.loading({ content: 'Brisanje prijemnice...', key });
    api
      .post(`invoice/delete`, { id })
      .then((response) => {
        getInvoices();
        message.success({
          content: `Prijemnica broj ${response.data.result.number} obrisana!`,
          key,
          duration: 5,
        });
      })
      .catch(() => {
        message.error({
          content: 'Greška pri brisanju prijemnice.',
          key,
          duration: 5,
        });
      });
  };

  const columns = [
    {
      title: 'Tag',
      dataIndex: 'locked',
      key: 'lockedTag',
      width: 'min-content',
      render: (value) => <img src={value ? locked : unlocked} alt='' />,
    },
    {
      title: 'Info',
      dataIndex: 'number',
      key: 'info',
      width: '100%',
      render: (value, row) => (
        <Row>
          <Col>
            <Row>
              <Text strong>{value}</Text>
            </Row>
            <Row>
              <Text strong type='success'>
                {row.supplier ? row.supplier.name : '-'}
              </Text>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: 'composer',
      dataIndex: 'composer',
      key: 'composer',
      width: 'min-content',
      color: '#8C8C8C',
      render: (value) => (
        <Text strong type='secondary' style={{ whiteSpace: 'nowrap', float: 'right' }}>
          {value ? value.name : '-'}
        </Text>
      ),
    },
    {
      title: 'locked',
      dataIndex: 'locked',
      width: 'min-content',
      key: 'locked',
      render: (value, invoice) =>
        value ? (
          <Tooltip title={`Zaključano ${moment(invoice.lockedAt).format(settings.fullDate)}`}>
            <Tag icon={<LockTwoTone twoToneColor='#585858' />} color='default'>
              Zaključano
            </Tag>
          </Tooltip>
        ) : (
          <Tag icon={<UnlockTwoTone twoToneColor='#52C41A' />} color='success'>
            Otključano
          </Tag>
        ),
    },
    {
      title: 'Akcije',
      dataIndex: 'id',
      width: 'min-content',
      key: 'action',
      render: (id, invoice) => (
        <Space size='small' onClick={(e) => e.stopPropagation()}>
          <EditInvoiceModal invoice={invoice} onOk={getInvoices}>
            <Button type='link'>Izmjeni</Button>
          </EditInvoiceModal>
          <div>
            <Popconfirm
              title='Da li ste sigurni da želite da obrišete prijemnicu?'
              onConfirm={() =>
                invoice.locked
                  ? message.error({
                      content: 'Nije moguće obrisati zaključanu prijemnicu.',
                      duration: 5,
                    })
                  : onDelete(id)
              }
              okText='Obriši'
              placement='rightTop'
              okButtonProps={{ danger: true }}
              cancelText='Otkaži'>
              <Button type='link'>Obriši</Button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  return (
    <RouteLayout
      title={
        <Text>
          Prijemnice{' '}
          {invoices.total && invoices.total !== 0 ? (
            <Text type='secondary' style={{ fontSize: '15px' }}>
              ({invoices.total} prijemnica ukupno)
            </Text>
          ) : null}
        </Text>
      }
      showBG={false}
      extra={[
        <AddInvoiceModal key='1' onOk={getInvoices}>
          <Button type='primary'>Dodaj novu prijemnicu</Button>
        </AddInvoiceModal>,
      ]}
      routes={[
        {
          path: '/',
          breadcrumbName: 'Foresta',
        },
        {
          breadcrumbName: 'Prijemnice',
        },
      ]}>
      {!loading ? (
        Object.keys(invoices.data).length !== 0 ? (
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            {Object.keys(invoices.data).map((date, index) => (
              <React.Fragment key={index}>
                <Card size='small' title={date}>
                  <Table
                    onRow={(row, rowIndex) => {
                      return {
                        onClick: () => history.push(settings.route.invoice.replace(':id', row.id)),
                      };
                    }}
                    bordered={false}
                    rowClassName='cursorPointer'
                    rowKey='id'
                    showHeader={false}
                    dataSource={invoices.data[date]}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    size='small'
                  />
                </Card>
              </React.Fragment>
            ))}
            <Row>
              <Col flex='auto' />
              <Col>
                <Pagination
                  current={query.page + 1}
                  pageSize={query.perPage}
                  pageSizeOptions={[10, 20, 50]}
                  showSizeChanger={true}
                  onChange={(page, pageSize) => setQuery({ page: page - 1, perPage: pageSize })}
                  total={invoices.total}
                />
              </Col>
            </Row>
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema prijemnica.' />
        )
      ) : (
        <Spin className='loadingPage' />
      )}
    </RouteLayout>
  );
};

export default Invoices;
