export const setUser = (token, name, username, role) => {
  localStorage.setItem('token', token);
  localStorage.setItem('name', name);
  localStorage.setItem('username', username);
  localStorage.setItem('role', role);
};

export const logout = () => {
  localStorage.clear();
};

export const getUser = () => {
  return {
    token: localStorage.getItem('token'),
    name: localStorage.getItem('name'),
    username: localStorage.getItem('username'),
    role: localStorage.getItem('role'),
  };
};
