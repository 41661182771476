import {
  MailOutlined,
  PhoneOutlined,
  SearchOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, Input, message, Modal, Switch } from 'antd';
import 'moment/locale/sr';
import React, { useState } from 'react';
import api from '../../../utils/api';

const EditSupplierModal = ({ children, onOk, supplier, ...rest }) => {
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    let key = 'supplierEdit';
    message.loading({ content: 'Izmjena dobavljača...', key });
    setLoading(true);
    api
      .post(`supplier/edit`, {
        id: supplier.id,
        name: values.name,
        phone: values.phone,
        email: values.email,
        address: values.address,
        tax: values.tax,
      })
      .then(() => {
        message.success({
          content: `Dobavljač uspješno izmjenjen!`,
          key,
          duration: 5,
        });
        setVisible(false);
        onOk();
        setLoading(false);
      })
      .catch((error) => {
        let errors = error.response ? error.response.data.errors : null;
        if (errors) {
          errors.forEach(
            (err) =>
              err.msg === 'duplicate_name' &&
              form.setFields([
                {
                  name: 'name',
                  errors: ['Dobavljač sa ovim imenom već postoji!'],
                },
              ])
          );
        }
        message.error({
          content: 'Greška pri izmjeni dobavljača.',
          key,
          duration: 5,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Modal
        confirmLoading={loading}
        visible={visible}
        title='Izmjena dobavljača'
        okText='Izmjeni dobavljača'
        cancelText='Otkaži'
        onCancel={() => setVisible(false)}
        onOk={() => {
          form.submit();
        }}>
        <Form layout='vertical' size='middle' onFinish={onFinish} requiredMark={false} form={form}>
          <Form.Item
            name='name'
            label='Ime dobavljača'
            initialValue={supplier.name}
            prefix={<UserOutlined />}
            rules={[
              {
                required: true,
                message: 'Unesite ime dobavljača!',
              },
            ]}>
            <Input
              type='text'
              style={{ width: '100%' }}
              prefix={<SearchOutlined />}
              placeholder='Unesite ime dobavljača'
            />
          </Form.Item>
          <Form.Item name='phone' initialValue={supplier.phone} label='Telefon'>
            <Input
              type='tel'
              style={{ width: '100%' }}
              prefix={<PhoneOutlined />}
              placeholder='Unesite kontakt telefon'
            />
          </Form.Item>
          <Form.Item initialValue={supplier.email} name='email' label='Email'>
            <Input
              type='email'
              style={{ width: '100%' }}
              prefix={<MailOutlined />}
              placeholder='Unesite email'
            />
          </Form.Item>
          <Form.Item initialValue={supplier.address} name='address' label='Adresa'>
            <Input
              type='text'
              style={{ width: '100%' }}
              prefix={<SendOutlined />}
              placeholder='Unesite adresu'
            />
          </Form.Item>
          <Form.Item initialValue={supplier.tax} name='tax' label='Vrsta dobavljača'>
            <Switch
              checkedChildren='Pravno lice ( SA PDV-om )'
              unCheckedChildren='Fizičko lice ( BEZ PDV-a )'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditSupplierModal;
