import {
  Form,
  Modal,
  Col,
  Row,
  message,
  Table,
  Divider,
  Statistic,
  Card,
  InputNumber,
  Empty,
} from 'antd';
import React, { useState } from 'react';
import 'moment/locale/sr';
import api from '../../../utils/api';
import roundingMethod from '../../../utils/roundingMethod';
import { useEffect } from 'reactn';
import settings from '../../../settings';

const EditInvoicePricesModal = ({ children, onOk, invoice, ...rest }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Refresh on update.
  useEffect(() => {
    setSort(invoice.sort);
    setPrices(invoice.prices);

    let newPrices = {};
    invoice.prices &&
      Object.keys(invoice.prices).forEach(
        (type) =>
          invoice.prices[type] &&
          Object.keys(invoice.prices[type]).forEach(
            (clas) => (newPrices[`${type}|${clas}|price`] = invoice.prices[type][clas])
          )
      );
    form.setFieldsValue(newPrices);

    setTotals({
      price: invoice.price,
      surcharge: invoice.surcharge,
      totalprice: invoice.totalprice,
    });
  }, [invoice, form]);

  const [totals, setTotals] = useState({
    price: invoice.price,
    surcharge: invoice.surcharge,
    totalprice: invoice.totalprice,
  });
  const [sort, setSort] = useState(invoice.sort || {});
  const [prices, setPrices] = useState(invoice.prices || {});

  const recalculate = (value) => {
    if (value[0]) {
      const name = value[0].name[0];
      const [type, clas] = name.split('|');
      const price = value[0].value;
      setPrices({ ...prices, [type]: { ...prices[type], [clas]: price } });
      const oldPrice = sort[type].classes[clas].price;
      const newPrice = roundingMethod(sort[type].classes[clas].totalvolume * price);
      let actPrice = roundingMethod(
        Number(totals.price * 100) - Number(oldPrice * 100) + Number(newPrice * 100)
      );
      actPrice = actPrice / 100;
      setTotals({
        price: roundingMethod(actPrice),
        surcharge: roundingMethod(actPrice * ((invoice.taxRate * 100 - 100) / 100)),
        totalprice: roundingMethod(actPrice * (invoice.tax ? invoice.taxRate : 1)),
      });
      setSort({
        ...sort,
        [type]: {
          ...sort[type],
          price: roundingMethod(sort[type].price - oldPrice + newPrice),
          classes: {
            ...sort[type].classes,
            [clas]: {
              ...sort[type].classes[clas],
              price: newPrice,
            },
          },
        },
      });
    }
  };

  const onFinish = () => {
    let key = 'invoiceEditPrices';
    message.loading({ content: 'Izmjena cijena prijemnice...', key });
    setLoading(true);
    api
      .post(`invoice/edit`, {
        id: invoice.id,
        prices,
      })
      .then((response) => {
        message.success({
          content: `Prijemnica broj ${response.data.result.number} uspješno izmjenjena!`,
          key,
          duration: 5,
        });
        setVisible(false);
        setLoading(false);
        onOk();
      })
      .catch(() => {
        message.error({
          content: 'Greška pri izmjeni cijena prijemnice.',
          key,
          duration: 5,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      {invoice.measurer && (
        <Modal
          {...rest}
          confirmLoading={loading}
          visible={visible}
          title='Rezime i cijene'
          okText='Izmjeni cijene'
          width='70%'
          cancelText='Otkaži'
          footer={invoice.locked ? false : undefined}
          onCancel={() => setVisible(false)}
          onOk={() => form.submit()}>
          {Object.keys(sort).length !== 0 ? (
            <>
              <Card>
                <Row gutter={16}>
                  <Col flex='1 1'>
                    <Statistic title='Ukupno trupaca' value={invoice.total} />
                  </Col>
                  <Col flex='1 1'>
                    <Statistic
                      title='Ukupna zapremina'
                      value={`${invoice.totalvolume} ${settings.volumeUnit}`}
                    />
                  </Col>
                  {invoice.tax && (
                    <>
                      <Col flex='1 1'>
                        <Statistic
                          title={`Ukupna cijena`}
                          value={`${totals.price} ${settings.currency}`}
                        />
                      </Col>
                      <Col flex='1 1'>
                        <Statistic
                          title={`Porez (${Math.ceil((invoice.taxRate - 1) * 100)}%)`}
                          value={`${totals.surcharge} ${settings.currency}`}
                        />
                      </Col>
                    </>
                  )}
                  <Col flex='1 1'>
                    <Statistic
                      title={`Ukupna cijena${invoice.tax ? ' (+ PDV)' : ''}`}
                      value={`${totals.totalprice} ${settings.currency}`}
                    />
                  </Col>
                </Row>
              </Card>
              <div className='modalScrollableContainer'>
                <Form
                  layout='vertical'
                  size='middle'
                  onFinish={onFinish}
                  requiredMark={false}
                  form={form}
                  onFieldsChange={(value) => recalculate(value)}>
                  {sort &&
                    Object.keys(sort).map((type, index) => {
                      let obj = sort[type];
                      let classes = Object.keys(obj.classes).reduce((classes, clas) => {
                        classes.push({ ...obj.classes[clas], class: clas });
                        return classes;
                      }, []);
                      let classesCount = Object.keys(obj.classes).length;

                      return (
                        <React.Fragment key={index}>
                          <Divider orientation='left'>{type}</Divider>
                          <Table
                            size='small'
                            dataSource={classes}
                            rowKey='class'
                            pagination={false}
                            summary={() => (
                              <Table.Summary.Row className='summaryRow'>
                                <Table.Summary.Cell>{`${classesCount} ${
                                  classesCount === 2 || classesCount === 3 || classesCount === 4
                                    ? 'klase'
                                    : 'klasa'
                                }`}</Table.Summary.Cell>
                                <Table.Summary.Cell>{`${obj.total} trupaca`}</Table.Summary.Cell>
                                <Table.Summary.Cell>{`${obj.totalvolume} ${settings.volumeUnit}`}</Table.Summary.Cell>
                                <Table.Summary.Cell
                                  colSpan={
                                    2
                                  }>{`${obj.price} ${settings.currency}`}</Table.Summary.Cell>
                              </Table.Summary.Row>
                            )}
                            columns={[
                              {
                                title: 'Klasa',
                                dataIndex: 'class',
                                key: 'class',
                                width: invoice.locked ? '20%' : '10%',
                              },
                              {
                                title: 'Ukupno trupaca',
                                dataIndex: 'total',
                                key: 'total',
                                width: '20%',
                              },
                              {
                                title: 'Ukupna zapremina',
                                dataIndex: 'totalvolume',
                                key: 'totalvolume',
                                width: '20%',
                                render: (value) => `${value} ${settings.volumeUnit}`,
                              },
                              {
                                title: 'Ukupna cijena',
                                dataIndex: 'price',
                                key: 'price',
                                width: '20%',
                                render: (value) => `${value} ${settings.currency}`,
                              },
                              {
                                title: `Cijena po ${settings.volumeUnit}`,
                                dataIndex: 'class',
                                width: invoice.locked ? '20%' : '30%',
                                key: 'priceinput',
                                render: (value) =>
                                  invoice.locked ? (
                                    `${
                                      prices[type] && prices[type][value]
                                        ? prices[type][value]
                                        : '0'
                                    } ${settings.currency}`
                                  ) : (
                                    <Form.Item
                                      noStyle
                                      name={`${type}|${value}|price`}
                                      initialValue={prices[type] && prices[type][value]}>
                                      <InputNumber
                                        precision={2}
                                        placeholder='Cijena'
                                        min={0}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  ),
                              },
                            ]}
                          />
                        </React.Fragment>
                      );
                    })}
                </Form>
              </div>
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Modal>
      )}
    </>
  );
};

export default EditInvoicePricesModal;
