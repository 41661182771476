import React, { useCallback, useEffect, useState } from 'react';
import { Table, Empty } from 'antd';
import RouteLayout from '../components/RouteLayout';
import api from '../utils/api';
import settings from '../settings';
import moment from 'moment';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUsers = useCallback((setload = true) => {
    if (setload) {
      setLoading(true);
    }
    api
      .get(`users/get`)
      .then((response) => {
        setLoading(false);
        setUsers(response.data.users);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const columns = [
    {
      title: 'Ime',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Titula',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Poslednji put aktivan',
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (value) => moment(value).fromNow(),
    },
    {
      title: 'Aktivan',
      dataIndex: 'active',
      key: 'active',
      render: (value) => (value ? 'Da' : 'Ne'),
    },
  ];

  return (
    <RouteLayout
      title='Korisnici'
      routes={[
        {
          path: settings.route.home,
          breadcrumbName: 'Foresta',
        },
        {
          breadcrumbName: 'Korisnici',
        },
      ]}>
      <Table
        tableLayout='auto'
        rowKey='id'
        loading={loading}
        dataSource={users}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema korisnika.' />,
        }}
        columns={columns}
        pagination={false}
        size='small'
      />
    </RouteLayout>
  );
};

export default Users;
