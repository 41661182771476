import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Descriptions,
  message,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import RouteLayout from '../../components/RouteLayout';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory } from 'react-router-dom';
import settings from '../../settings';
import moment from 'moment';
import { useQuery } from '../../utils/useQuery';

const TrashList = () => {
  const history = useHistory();
  const params = useQuery();
  const [logs, setLogs] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(true);
  const logsType = params.get('vrsta');
  const logsClass = params.get('klasa');
  const [query, setQuery] = useState({
    page: 0,
    perPage: 50,
    type: logsType,
    class: logsClass,
  });

  const getLogs = useCallback(() => {
    setLoading(true);
    api
      .get(`inventory/trash/list`, { params: query })
      .then((response) => {
        setLogs({ data: response.data.trash, total: response.data.total });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [query]);

  const untrashLog = (id) => {
    const key = 'logunTrash';
    setLoading(true);
    api
      .post(`log/edit`, {
        id,
        trashed: false,
      })
      .then((response) => {
        message.success({
          content: `Trupac broj ${response.data.result.plate} uspješno vraćen na stanje!`,
          key,
          duration: 5,
        });
        getLogs();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => getLogs(), [getLogs]);

  const columns = [
    {
      title: 'Broj pločice',
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: 'Prijemnica',
      dataIndex: 'invoice',
      key: 'invoiceNumber',
      render: (invoice) => (
        <Button
          size='small'
          type='secondary'
          onClick={(e) => {
            e.stopPropagation();
            history.push(settings.route.invoice.replace(':id', invoice.id));
          }}>
          <Text type='success'>{invoice.number}</Text>
        </Button>
      ),
    },
    {
      title: 'Vrsta',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Klasa',
      dataIndex: 'class',
      key: 'class',
    },
    {
      title: `Dužina (${settings.logUnit})`,
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: `Prečnik (${settings.logUnit})`,
      dataIndex: 'radius',
      key: 'radius',
    },
    {
      title: `Zapremina (${settings.volumeUnit})`,
      dataIndex: 'volume',
      key: 'volume',
    },
    {
      title: 'Vraćen',
      dataIndex: 'trashedAt',
      key: 'trashedAt',
      render: (value) => moment(value).format(settings.fullDate),
    },
    {
      title: 'Akcija',
      dataIndex: 'id',
      key: 'action',
      align: 'center',
      width: 'min-content',
      render: (id, row) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            title='Da li ste sigurni da želite da vratite trupac na stanje?'
            onConfirm={() => untrashLog(id)}
            okText='Vrati'
            placement='topLeft'
            okButtonProps={{ danger: true }}
            cancelText='Otkaži'>
            <Button type='link'>Vrati na stanje</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <RouteLayout
      title={<Text>Vraćeni trupci</Text>}
      showBG={false}
      descriptions={
        !loading &&
        logsType && (
          <Descriptions size='middle' column={4}>
            <Descriptions.Item label={<Text strong>Vrsta</Text>}>
              {logsType.charAt(0).toUpperCase() + logsType.slice(1)}
            </Descriptions.Item>
            {logsClass && (
              <Descriptions.Item label={<Text strong>Klasa</Text>}>
                {logsClass.toUpperCase().replace('-', '/')}
              </Descriptions.Item>
            )}
          </Descriptions>
        )
      }
      routes={[
        {
          path: '/',
          breadcrumbName: 'Foresta',
        },
        {
          path: settings.route.trash,
          breadcrumbName: 'Vraćeni trupci',
        },
        logsType
          ? {
              breadcrumbName: logsType.charAt(0).toUpperCase() + logsType.slice(1),
            }
          : {},
      ]}>
      {!loading ? (
        <Space direction='vertical' size='middle' style={{ width: '100%' }}>
          <Table
            onRow={(row, rowIndex) => {
              return {
                onClick: () => history.push(settings.route.loginfo.replace(':id', row.id)),
              };
            }}
            bordered={false}
            rowClassName='cursorPointer'
            rowKey='id'
            dataSource={logs.data}
            loading={loading}
            pagination={false}
            columns={columns}
            size='small'
          />
          <Row>
            <Col flex='auto' />
            <Col>
              <Pagination
                current={query.page + 1}
                pageSize={query.perPage}
                pageSizeOptions={[50, 100, 200]}
                showSizeChanger={true}
                onChange={(page, pageSize) =>
                  setQuery({ ...query, page: page - 1, perPage: pageSize })
                }
                total={logs.total}
              />
            </Col>
          </Row>
        </Space>
      ) : (
        <Spin className='loadingPage' />
      )}
    </RouteLayout>
  );
};

export default TrashList;
