import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Descriptions, Pagination, Row, Space, Spin, Table } from 'antd';
import RouteLayout from '../../components/RouteLayout';
import Text from 'antd/lib/typography/Text';
import api from '../../utils/api';
import { useHistory } from 'react-router-dom';
import settings from '../../settings';
import { useQuery } from '../../utils/useQuery';

const HistoryList = () => {
  const navhistory = useHistory();
  const params = useQuery();
  const [logs, setLogs] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(true);
  const logsType = params.get('vrsta');
  const logsClass = params.get('klasa');
  const [query, setQuery] = useState({
    page: 0,
    perPage: 50,
    type: logsType,
    class: logsClass,
  });

  const getLogs = useCallback(() => {
    setLoading(true);
    api
      .get(`inventory/history/list`, { params: query })
      .then((response) => {
        setLogs({ data: response.data.history, total: response.data.total });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [query]);

  useEffect(() => getLogs(), [getLogs]);

  const columns = [
    {
      title: 'Broj pločice',
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: 'Prijemnica',
      dataIndex: 'invoice',
      key: 'invoiceNumber',
      render: (invoice) => (
        <Button
          size='small'
          type='secondary'
          onClick={(e) => {
            e.stopPropagation();
            navhistory.push(settings.route.invoice.replace(':id', invoice.id));
          }}>
          <Text type='success'>{invoice.number}</Text>
        </Button>
      ),
    },
    {
      title: 'Vrsta',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Klasa',
      dataIndex: 'class',
      key: 'class',
    },
    {
      title: `Dužina (${settings.logUnit})`,
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: `Prečnik (${settings.logUnit})`,
      dataIndex: 'radius',
      key: 'radius',
    },
    {
      title: `Zapremina (${settings.volumeUnit})`,
      dataIndex: 'volume',
      key: 'volume',
    },
  ];

  return (
    <RouteLayout
      title={<Text>Istorija lagera</Text>}
      showBG={false}
      descriptions={
        !loading &&
        logsType && (
          <Descriptions size='middle' column={4}>
            <Descriptions.Item label={<Text strong>Vrsta</Text>}>
              {logsType.charAt(0).toUpperCase() + logsType.slice(1)}
            </Descriptions.Item>
            {logsClass && (
              <Descriptions.Item label={<Text strong>Klasa</Text>}>
                {logsClass.toUpperCase().replace('-', '/')}
              </Descriptions.Item>
            )}
          </Descriptions>
        )
      }
      routes={[
        {
          path: '/',
          breadcrumbName: 'Foresta',
        },
        {
          path: settings.route.history,
          breadcrumbName: 'Istorija lagera',
        },
        logsType
          ? {
              breadcrumbName: logsType.charAt(0).toUpperCase() + logsType.slice(1),
            }
          : {},
      ]}>
      {!loading ? (
        <Space direction='vertical' size='middle' style={{ width: '100%' }}>
          <Table
            onRow={(row, rowIndex) => {
              return {
                onClick: () => navhistory.push(settings.route.loginfo.replace(':id', row.id)),
              };
            }}
            bordered={false}
            rowClassName='cursorPointer'
            rowKey='id'
            dataSource={logs.data}
            loading={loading}
            pagination={false}
            columns={columns}
            size='small'
          />
          <Row>
            <Col flex='auto' />
            <Col>
              <Pagination
                current={query.page + 1}
                pageSize={query.perPage}
                pageSizeOptions={[50, 100, 200]}
                showSizeChanger={true}
                onChange={(page, pageSize) =>
                  setQuery({ ...query, page: page - 1, perPage: pageSize })
                }
                total={logs.total}
              />
            </Col>
          </Row>
        </Space>
      ) : (
        <Spin className='loadingPage' />
      )}
    </RouteLayout>
  );
};

export default HistoryList;
