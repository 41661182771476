import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Empty,
  message,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import RouteLayout from "../../components/RouteLayout";
import { LockTwoTone, UnlockTwoTone } from "@ant-design/icons";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import AddSawlistModal from "./modals/AddSawlistModal";
import api from "../../utils/api";
import { useHistory } from "react-router-dom";
import settings from "../../settings";
import locked from "../../img/locked.svg";
import unlocked from "../../img/unlocked.svg";
import useSawlists from "../../socketio/useSawlists";
import { useQuery } from "../../utils/useQuery";

const Sawlists = () => {
  const history = useHistory();
  const params = useQuery();
  const [sawlists, setSawlists] = useState({ data: [], total: 0 });
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({
    page: +params.get("strana") > 0 ? +params.get("strana") - 1 : 0,
    perPage: 10,
  });

  const getSawlists = useCallback(
    (loading = true) => {
      setLoading(loading);
      window.history.replaceState(
        null,
        null,
        `${settings.route.sawlists}?strana=${query.page + 1}`
      );
      api
        .get(`sawlists`, { params: { ...query, group: true } })
        .then((response) => {
          setSawlists({
            data: response.data.sawlists,
            total: response.data.total,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [query]
  );

  useEffect(() => getSawlists(), [getSawlists]);

  const onUpdate = (event, name, extra) => {
    if (event === "edit") {
      message.info({
        content: `${name} je upravo izmjenio listu prerade dobavljača ${extra}.`,
        duration: 3,
      });
    } else if (event === "delete") {
      message.warning({
        content: `${name} je upravo obrisao listu prerade dobavljača ${extra}!`,
        duration: 3,
      });
    } else if (event === "add") {
      message.success({
        content: `${name} je upravo dodao listu prerade dobavljača ${extra}.`,
        duration: 3,
      });
    }
    getSawlists(false);
  };

  useSawlists(onUpdate);

  const onDelete = (id) => {
    let key = "sawlistDeletion";
    message.loading({ content: "Brisanje liste prerade...", key });
    api
      .post(`sawlist/delete`, { id })
      .then((response) => {
        getSawlists();
        message.success({
          content: `Lista prerade dobavljača ${response.data.result.supplier.name} obrisana!`,
          key,
          duration: 5,
        });
      })
      .catch(() => {
        message.error({
          content: "Greška pri brisanju liste prerade.",
          key,
          duration: 5,
        });
      });
  };

  const columns = [
    {
      title: "Tag",
      dataIndex: "locked",
      key: "lockedTag",
      width: "min-content",
      render: (value) => <img src={value ? locked : unlocked} alt="" />,
    },
    {
      title: "Info",
      dataIndex: "supplier",
      key: "info",
      width: "100%",
      render: (value) => (
        <Text strong type="success">
          {value ? value.name : "-"}
        </Text>
      ),
    },
    {
      title: "locked",
      dataIndex: "locked",
      width: "min-content",
      key: "locked",
      render: (value, sawlist) =>
        value ? (
          <Tooltip
            title={`Zaključano ${moment(sawlist.lockedAt).format(
              settings.fullDate
            )}`}
          >
            <Tag icon={<LockTwoTone twoToneColor="#585858" />} color="default">
              Zaključano
            </Tag>
          </Tooltip>
        ) : (
          <Tag icon={<UnlockTwoTone twoToneColor="#52C41A" />} color="success">
            Otključano
          </Tag>
        ),
    },
    {
      title: "Akcije",
      dataIndex: "id",
      width: "min-content",
      key: "action",
      render: (id, sawlist) => (
        <Space size="small" onClick={(e) => e.stopPropagation()}>
          <div>
            <Popconfirm
              title="Da li ste sigurni da želite da obrišete listu prerade?"
              onConfirm={() =>
                sawlist.locked
                  ? message.error({
                      content: "Nije moguće obrisati zaključanu listu prerade.",
                      duration: 5,
                    })
                  : onDelete(id)
              }
              okText="Obriši"
              placement="rightTop"
              okButtonProps={{ danger: true }}
              cancelText="Otkaži"
            >
              <Button type="link">Obriši</Button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  return (
    <RouteLayout
      title={
        <Text>
          Prerada{" "}
          {sawlists.total && sawlists.total !== 0 ? (
            <Text type="secondary" style={{ fontSize: "15px" }}>
              ({sawlists.total} listi prerada ukupno)
            </Text>
          ) : null}
        </Text>
      }
      showBG={false}
      extra={[
        <AddSawlistModal key="1" onOk={getSawlists}>
          <Button type="primary">Dodaj novu listu prerade</Button>
        </AddSawlistModal>,
      ]}
      routes={[
        {
          path: "/",
          breadcrumbName: "Foresta",
        },
        {
          breadcrumbName: "Prerada",
        },
      ]}
    >
      {!loading ? (
        Object.keys(sawlists.data).length !== 0 ? (
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {Object.keys(sawlists.data).map((date, index) => (
              <React.Fragment key={index}>
                <Card size="small" title={date}>
                  <Table
                    onRow={(row, rowIndex) => {
                      return {
                        onClick: () =>
                          history.push(
                            settings.route.sawlist.replace(":id", row.id)
                          ),
                      };
                    }}
                    bordered={false}
                    rowClassName="cursorPointer"
                    rowKey="id"
                    showHeader={false}
                    dataSource={sawlists.data[date]}
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    size="small"
                  />
                </Card>
              </React.Fragment>
            ))}
            <Row>
              <Col flex="auto" />
              <Col>
                <Pagination
                  current={query.page + 1}
                  pageSize={query.perPage}
                  pageSizeOptions={[10, 20, 50]}
                  showSizeChanger={true}
                  onChange={(page, pageSize) =>
                    setQuery({ page: page - 1, perPage: pageSize })
                  }
                  total={sawlists.total}
                />
              </Col>
            </Row>
          </Space>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nema listi prerada."
          />
        )
      ) : (
        <Spin className="loadingPage" />
      )}
    </RouteLayout>
  );
};

export default Sawlists;
