import { Button, Dropdown, Menu, message } from 'antd';
import React from 'react';
import api from '../../utils/api';

const InvoicePrint = ({ locked, id }) => {
  const getInvoice = (type) =>
    api
      .post(`invoice/print`, { id, type }, { responseType: 'arraybuffer' })
      .then((response) => {
        const w = parseInt(window.innerWidth) * 0.5;
        const h = parseInt(window.innerHeight) * 0.8;
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth
          ? window.innerWidth
          : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : window.screen.width;
        const height = window.innerHeight
          ? window.innerHeight
          : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;

        var win = window.open(
            '',
            'Prijemnica',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no, width=${w}, height=${h},top=${top}, left=${left}`
          ),
          iframe = document.createElement('iframe'),
          title = document.createElement('title'),
          file = new Blob([response.data], {
            type: 'application/pdf',
          }),
          fileUrl = URL.createObjectURL(file);

        title.appendChild(document.createTextNode('Prijemnica'));
        win.document.body.innerHTML = '';
        iframe.src = fileUrl;
        iframe.width = '100%';
        iframe.height = '100%';
        iframe.style.border = 'none';

        win.document.head.appendChild(title);
        win.document.body.appendChild(iframe);
        win.document.body.style.margin = 0;

        if (!win || win.closed || typeof win.closed == 'undefined') {
          file = new Blob([response.data], {
            type: 'application/pdf',
          });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_self');
        }
      })
      .catch((error) => {
        message.error({
          content: 'Greška pri štampi prijemnice.',
          duration: 5,
        });
      });

  return (
    <Dropdown
      trigger='click'
      overlay={
        <Menu>
          <Menu.Item onClick={() => getInvoice('noprice')}>Bez cijene</Menu.Item>
          <Menu.Item onClick={() => getInvoice('preinvoice')}>Predračun</Menu.Item>
          <Menu.Item onClick={() => getInvoice('invoice')} disabled={!locked}>
            Račun
          </Menu.Item>
        </Menu>
      }>
      <Button type='secondary'>Štampaj</Button>
    </Dropdown>
  );
};

export default InvoicePrint;
