import React, { useState, useCallback } from 'react';
import locale from 'antd/lib/locale/sr_RS';
import {
  Badge,
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Layout,
  Menu,
  message,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import 'antd/dist/antd.less';
import './index.css';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  DownOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons';
import Sidebar from './components/sidebar';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Invoices from './routes/invoice/invoices';
import Invoice from './routes/invoice/invoice';
import Suppliers from './routes/supplier/suppliers';
import Login from './routes/login';
import Avatar from 'antd/lib/avatar/avatar';
import { getUser, logout } from './utils/cookies';
import Text from 'antd/lib/typography/Text';
import settings from './settings';
import { useGlobal } from 'reactn';
import api, { socket } from './utils/api';
import Stock from './routes/inventory/stock';
import StockList from './routes/inventory/stocklist';
import History from './routes/inventory/history';
import HistoryList from './routes/inventory/historylist';
import LogInfo from './routes/loginfo';
import Element from './routes/elements/elements';
import ElementsList from './routes/elements/elementslist';
import Supplier from './routes/supplier/supplier';
import useSetting from './socketio/useSetting';
import Admin from './routes/admin';
import Users from './routes/users';
import mixpanel from 'mixpanel-browser';
import Sawlists from './routes/sawlist/sawlists';
import Sawlist from './routes/sawlist/sawlist';
import TrashList from './routes/inventory/trashlist';
import Trash from './routes/inventory/trash';

const { Header } = Layout;

export const history = createBrowserHistory();
mixpanel.init('be985ca54b3645a4043b608ff4cac32a');

export default function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [token, setToken] = useState(getUser().token);
  const [companySettings, setCompanySettings] = useGlobal('settings');

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const getSettings = () => {
    api
      .get(`settings`)
      .then((response) => {
        setCompanySettings(response.data);
      })
      .catch(() => {
        setCompanySettings({ types: [], classes: [] });
      });
  };

  useSetting((name) => {
    message.warning({
      content: `${name} je upravo izmjenio podešavanja firme!`,
      duration: 5,
    });
    getSettings();
  });

  if (!token) {
    return <Login {...{ setToken }} />;
  }

  // Analytics.
  mixpanel.identify(getUser().username);
  mixpanel.people.set({
    USER_ID: getUser().username,
    Name: getUser().name,
  });

  if (!companySettings) {
    getSettings();
    return <Spin size='large' className='loadingScreen' />;
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  socket.on('connect', function () {
    forceUpdate();
  });

  socket.on('disconnect', function () {
    forceUpdate();
  });

  return (
    <ConfigProvider locale={locale}>
      <Router history={history}>
        <Layout className='mainLayout'>
          <Sidebar collapsed={collapsed} />
          <Layout className='site-layout'>
            <Header className='top-header'>
              <Row gutter={30}>
                <Col flex='auto'>
                  {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: () => setCollapsed(!collapsed),
                  })}
                </Col>
                <Col>
                  <Tooltip
                    title={
                      socket.connected
                        ? 'Sinhronizovani ste sa drugim korisnicima! Sve izmjene će se automatski učitavati.'
                        : 'Niste sinhronizovani. Kliknite da uspostavite vezu.'
                    }>
                    <Badge
                      onClick={() => {
                        socket.connected ? socket.close() : socket.open();
                      }}
                      style={{ cursor: 'pointer' }}
                      color={socket.connected ? 'green' : 'red'}
                      status='success'
                      text={socket.connected ? 'Sinhronizovano' : 'Desinhronizovano'}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      openInNewTab(
                        'https://www.notion.so/cartasystems/Dokumentacija-910ddc151c064759b5772b4e824742ad'
                      )
                    }
                    icon={<QuestionCircleFilled />}>
                    Pomoć
                  </Button>
                </Col>
                <Col flex='none'>
                  <Dropdown
                    trigger='click'
                    overlay={
                      <Menu>
                        <Menu.Item
                          icon={<LogoutOutlined />}
                          danger
                          onClick={() => {
                            logout();
                            setToken(null);
                          }}>
                          Odjavi se
                        </Menu.Item>
                      </Menu>
                    }>
                    <Row gutter={5} style={{ userSelect: 'none', cursor: 'pointer' }}>
                      <Col>
                        <Avatar icon={<UserOutlined />} />
                      </Col>
                      <Col>
                        <Text strong>{getUser().name}</Text>
                      </Col>
                      <Col>
                        <DownOutlined />
                      </Col>
                    </Row>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Switch>
              <Route
                path={settings.route.home}
                exact
                render={() => <Redirect to={settings.route.invoices} />}
              />
              <Route path={settings.route.invoices} component={Invoices} />
              <Route path={settings.route.invoice} component={Invoice} />

              <Route exact path={settings.route.stock} component={Stock} />
              <Route path={settings.route.stocklist} component={StockList} />

              <Route exact path={settings.route.trash} component={Trash} />
              <Route path={settings.route.trashlist} component={TrashList} />

              <Route exact path={settings.route.history} component={History} />
              <Route path={settings.route.historylist} component={HistoryList} />

              <Route exact path={settings.route.sawlists} component={Sawlists} />
              <Route path={settings.route.sawlist} component={Sawlist} />

              <Route exact path={settings.route.element} component={Element} />
              <Route path={settings.route.elementlist} component={ElementsList} />

              <Route path={settings.route.loginfo} component={LogInfo} />

              <Route path={settings.route.admin} component={Admin} />
              <Route path={settings.route.users} component={Users} />

              <Route path={settings.route.suppliers} component={Suppliers} />
              <Route path={settings.route.supplier} component={Supplier} />
            </Switch>
          </Layout>
        </Layout>
      </Router>
    </ConfigProvider>
  );
}
